import { ErrorBoundary } from "@sentry/react";
import { ReactNode } from "react";

import { SentryErrorFallback } from "./SentryErrorFallback";

type Props = {
    children?: ReactNode;
};

export const SentryLoggingProvider: React.FC<Props> = ({ children }) => {
    return (
        <ErrorBoundary fallback={<SentryErrorFallback />} beforeCapture={(scope) => scope.setTag("app", "library-app")}>
            {children}
        </ErrorBoundary>
    );
};
