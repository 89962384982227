import { defineMessages } from "react-intl";

const messages = defineMessages({
    searchPlaceHolder: {
        id: "features.libraryOverview.components.searchBar.search",
        defaultMessage: "Search",
    },
});

export default messages;
