import { Api } from "@ignite-analytics/api-client";
import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { Guarded } from "@ignite-analytics/permissions";
import { useCallback } from "react";

import { useSessionContext } from "@/providers/sessionContextProvider";
import { Widget } from "@/types/widget";

export interface CustomDashboard extends Guarded {
    id: number;
    name: string;
    description: string;
    department: number;
    client: number;
    module: "home" | "analyze" | "prioritize" | "implement" | "followup" | "sourcing";
    dashboardCollection: number | null;
    owner: string;
    precedence: number;
    createdAt: string;
    updatedAt: string;
    widgets: Widget[];
}

type CustomDashboardListObject = Omit<CustomDashboard, "widgets">;
const detailResource = createDetailResource<CustomDashboard>`/dashboards`;
const listCreateResource = createListResource<CustomDashboardListObject>`/dashboards/user`;

export const {
    Provider: CustomDashboardContextProvider,
    useThisContext: useCustomDashboardContext,
    uncache: uncacheCustomDashboard,
} = createEntityContext(listCreateResource, detailResource, {
    interchangableTypes: false,
    name: "Custom dashboards",
});

export const useUpdateMultipleCustomDashboards = () => {
    const { addListObjs } = useCustomDashboardContext();
    const { id: userId } = useSessionContext();
    return useCallback(
        (customDashboardsToUpdate: CustomDashboardListObject[]) => {
            const dashboardsUrl = `/dashboards/user/${userId}/`;
            const url = dashboardsUrl;
            return Api.patch<CustomDashboardListObject[]>(url, customDashboardsToUpdate, {
                service: "dashboards",
            }).then((updatedCustomDashboards: CustomDashboardListObject[]) => {
                updatedCustomDashboards.forEach(({ id }) => uncacheCustomDashboard(id));
                addListObjs(updatedCustomDashboards, url);
                return updatedCustomDashboards;
            });
        },
        [addListObjs, userId]
    );
};
