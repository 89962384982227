import { PreservedGlobalTypes } from "@/features/importing/api/importing";
import { DataColumn } from "@/generated/client";

/**
 * A function meant for the component GlobalConfigureGlobalTypesTable, It takes inn preserved global types, data columns and group types, and returns a list of the PreservedGlobalTypes that does is not connected to a data column.
 * @param dataColumns: DataColumn[]
 * @param allGroupTypes: GroupType[]
 * @param preservedGlobalTypes: PreservedGlobalTypes
 * @param usingDMSV2: boolean
 * @returns a list of options of data columns or group types. Filters out all columns and group types that already is assigned a global type.
 */
export const getGlobalTypesToConfigure = (dataColumns: DataColumn[], preservedGlobalTypes: PreservedGlobalTypes) => {
    // Get all data columns with global types, then filter out all preserved global column types that are assigned to a data column
    const dataColumnsGlobalTypes = dataColumns
        .filter((dataColumn) => dataColumn.globalTypeKey)
        .map((dataColumn) => dataColumn.globalTypeKey);

    const preservedColumnTypesToConfigure = preservedGlobalTypes.preservedGlobalColumnTypes.filter(
        (globalColumnType) => {
            if (dataColumnsGlobalTypes.includes(globalColumnType.key)) return false;
            return true;
        }
    );

    // If using dms v2 only use global column types, else use both group types and column types
    return preservedColumnTypesToConfigure;
};
