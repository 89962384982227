import { FetchResult } from "@apollo/client";
import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import Close from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { CustomDashboard } from "@/entities/dashboards";
import { importDashboardFromLibrary, PreservedGlobalTypes } from "@/features/importing/api/importing";
import { ConfigureGlobalTypesTable } from "@/features/importing/components/ConfigureGlobalTypesTable";
import {
    DataTable,
    UpdateDataColumnMutation,
    UpdateDataTableMutation,
    useGetManyDataTablesQuery,
    useUpdateDataColumnMutation,
    useUpdateDataTableMutation,
} from "@/generated/client";
import { useInformUser } from "@/hooks/useInformUser";
import { fm } from "@/providers/IntlContextProvider";
import { Field, GlobalTypeMapping } from "@/types/globalTypeMapping";
import { AccentureDashboardLibraryItem, DashboardLibraryItem } from "@/types/libraryItem";

import { AddDashboardToCollection } from "../AddDashboardToCollection";

import messages from "./messages";

interface Props {
    open: boolean;
    onClose: () => void;
    libraryItem: DashboardLibraryItem | AccentureDashboardLibraryItem;
    preservedGlobalTypes: PreservedGlobalTypes[];
}

export const ConfigureGlobalTypesDialogForDashboard: React.FC<Props> = ({
    open,
    onClose,
    libraryItem,
    preservedGlobalTypes,
}) => {
    const informUser = useInformUser();
    const { result } = useGetManyDataTablesQuery({});

    const dashboardImport = {
        id: libraryItem.id,
        title: libraryItem.title,
        itemType: libraryItem.itemType,
    };
    const [loading, setLoading] = useState(false);

    const [globalTypeMapping, setGlobalTypeMapping] = useState<GlobalTypeMapping>({});

    const [updateDataColumn] = useUpdateDataColumnMutation();
    const [updateDataTable] = useUpdateDataTableMutation();

    const [openDashboardCollection, setOpenDashboardCollection] = useState(false);
    const [newDashboard, setNewDashboard] = useState<CustomDashboard>();

    const getGlobalTableTypesWithoutTable = (dataTables: DataTable[]) => {
        const dataTablesWithGlobalType = dataTables
            .filter(({ globalTypeKey }) => globalTypeKey)
            .map(({ globalTypeKey }) => globalTypeKey);

        return preservedGlobalTypes.filter((preservedGlobalType) => {
            return !dataTablesWithGlobalType.includes(preservedGlobalType.preservedGlobalTableType.key);
        });
    };

    const handleImportDashboard = () => {
        importDashboardFromLibrary(dashboardImport)
            .then((customDashboard) => {
                setLoading(false);
                setNewDashboard(customDashboard);
                setOpenDashboardCollection(true);
            })
            .catch(() => {
                setLoading(false);
                informUser({ message: fm(messages.failedToImportDashboard).toString(), type: "error" });
            });
    };
    const handleImportAndConfigureClick = (dataTables: DataTable[], skipConfiguration: boolean) => {
        setLoading(!loading);
        if (skipConfiguration && getGlobalTableTypesWithoutTable(dataTables).length === 0) {
            handleImportDashboard();
            return;
        }
        if (globalTypeMapping) {
            const promises: (
                | Promise<
                      | Field
                      | FetchResult<UpdateDataTableMutation, Record<string, unknown>, Record<string, unknown>>
                      | FetchResult<UpdateDataColumnMutation, Record<string, unknown>, Record<string, unknown>>
                      | undefined
                  >
                | undefined
            )[] = Object.values(globalTypeMapping)
                .filter((field) => field.updated)
                .flatMap((field) => {
                    if ("elasticIndex" in field && field.elasticIndex.startsWith("datatable_v2")) {
                        return updateDataTable({
                            input: {
                                id: String(field.id),
                                update: {
                                    globalTypeKey: String(field.globalTypeKey),
                                },
                                mask: ["globalTypeKey"],
                            },
                        });
                    }
                    if ("dataTableId" in field && typeof field.dataTableId === "string") {
                        return updateDataColumn({
                            input: {
                                dataTableId: String(field.dataTableId),
                                id: String(field.id),
                                name: field.name,
                                globalTypeKey: field.globalTypeKey,
                                dataType: field.dataType,
                            },
                        });
                    }
                    return undefined;
                });

            Promise.all(promises)
                .then(() => {
                    handleImportDashboard();
                })
                .catch(() =>
                    informUser({ message: fm(messages.failedToConfigureGlobalTypes).toString(), type: "error" })
                );
        }
    };

    return (
        <>
            <Dialog
                open={open}
                title={fm(messages.configModalTitle, { itemName: libraryItem.title }).toString()}
                onClose={onClose}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6">
                            {fm(messages.configModalTitle, { itemName: libraryItem.title })}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <Stack direction="row" pt={1}>
                        <Typography variant="body1">{fm(messages.configModalDescription)}</Typography>
                    </Stack>
                </DialogTitle>

                <DialogContent>
                    <GraphqlRequestContainer
                        asyncData={result}
                        loading={
                            <>
                                <Skeleton height={30} width="100%" />
                                <Skeleton height={30} width="100%" />
                                <Skeleton height={30} width="100%" />
                            </>
                        }
                    >
                        {({ entities: dataTables }) => {
                            return (
                                <Formik
                                    onSubmit={() => {
                                        /* noop */
                                    }}
                                    validationSchema={Yup.object().shape({
                                        ...Object.fromEntries(
                                            Object.values(getGlobalTableTypesWithoutTable(dataTables)).map((pgt) => [
                                                pgt.preservedGlobalTableType.id,
                                                Yup.object().shape({
                                                    globalTableType: Yup.mixed()
                                                        .typeError(fm(messages.missingGlobalTableTypeError))
                                                        .required(fm(messages.missingGlobalTableTypeError)),
                                                }),
                                            ])
                                        ),
                                    })}
                                    initialValues={{}}
                                >
                                    <Form
                                        style={{
                                            width: "100%",
                                            overflowY: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {preservedGlobalTypes.map((preservedGlobalType) => (
                                            <ConfigureGlobalTypesTable
                                                key={preservedGlobalType.preservedGlobalTableType.id}
                                                preservedGlobalTypes={preservedGlobalType}
                                                setGlobalTypeMapping={setGlobalTypeMapping}
                                                globalTypeMapping={globalTypeMapping}
                                                dataTables={dataTables}
                                            />
                                        ))}
                                    </Form>
                                </Formik>
                            );
                        }}
                    </GraphqlRequestContainer>
                </DialogContent>

                <DialogActions>
                    <GraphqlRequestContainer asyncData={result}>
                        {({ entities: dataTables }) => {
                            const isGlobalTableTypesConfigured =
                                Object.keys(globalTypeMapping).filter((key) => key.startsWith("GlobalTableType_"))
                                    .length === getGlobalTableTypesWithoutTable(dataTables).length;
                            return (
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        disabled={loading}
                                        onClick={() => onClose()}
                                    >
                                        {fm(messages.cancelButton)}
                                    </Button>
                                    <Tooltip title={fm(messages.skipConfigurationTooltip)}>
                                        <Button
                                            variant="text"
                                            color="secondary"
                                            disabled={loading}
                                            onClick={() => handleImportAndConfigureClick(dataTables, true)}
                                        >
                                            {fm(messages.skipConfigurationButton)}
                                        </Button>
                                    </Tooltip>
                                    <LoadingButton
                                        disabled={!isGlobalTableTypesConfigured}
                                        loading={loading}
                                        variant="contained"
                                        size="medium"
                                        onClick={() => handleImportAndConfigureClick(dataTables, false)}
                                    >
                                        {fm(messages.configureButton)}
                                    </LoadingButton>
                                </Stack>
                            );
                        }}
                    </GraphqlRequestContainer>
                </DialogActions>
            </Dialog>
            <AddDashboardToCollection dashboard={newDashboard} open={openDashboardCollection} />
        </>
    );
};
