import { defineMessages } from "react-intl";

const messages = defineMessages({
    editLibraryItem: {
        id: "features.published.components.editLibraryItemModal.editLibraryItem",
        defaultMessage: "Edit library item",
    },
    pictureFileTypeAlert: {
        id: "features.published.components.editLibraryItemModal.pictureFileTypeAlert",
        defaultMessage: "Please select file of type: png, jpg, jpeg",
    },
    collections: {
        id: "features.published.components.editLibraryItemModal.collections",
        defaultMessage: "Collections",
    },
    description: {
        id: "features.published.components.editLibraryItemModal.description",
        defaultMessage: "Description",
    },
    title: {
        id: "features.published.components.editLibraryItemModal.title",
        defaultMessage: "Title",
    },
    preview: {
        id: "features.published.components.editLibraryItemModal.preview",
        defaultMessage: "Preview",
    },
    libraryItemUpdateSuccess: {
        id: "features.published.components.editLibraryItemModal.libraryItemUpdateSuccess",
        defaultMessage: "Successfully updated library item",
    },
    libraryItemDeleteSuccess: {
        id: "features.published.components.editLibraryItemModal.libraryItemDeleteSuccess",
        defaultMessage: "Library item deleted",
    },
    libraryItemUpdateFailure: {
        id: "features.published.components.editLibraryItemModal.libraryItemUpdateFailure",
        defaultMessage: "Could not update library item. Please contact support if the problem persists. ",
    },
    libraryItemDeleteFailure: {
        id: "features.published.components.editLibraryItemModal.libraryItemDeleteFailure",
        defaultMessage: "Could not delete library item. Please contact support if the problem persists.",
    },
    sureAboutDeletion: {
        id: "features.published.components.editLibraryItemModal.sureAboutDeletion",
        defaultMessage: "Are you sure you want to delete this library item from library?",
    },
    deleteLibraryDashboard: {
        id: "features.published.components.editLibraryItemModal.deleteLibraryDashboard",
        defaultMessage: "Delete library dashboard",
    },
    saveButton: {
        id: "features.published.components.editLibraryItemModal.saveButton",
        defaultMessage: "Save",
    },
    deleteButton: {
        id: "features.published.components.editLibraryItemModal.deleteButton",
        defaultMessage: "Delete",
    },
    invalidCollection: {
        id: "features.published.components.editLibraryItemModal.invalidCollection",
        defaultMessage: "Invalid collection",
    },
});

export default messages;
