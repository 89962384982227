import { defineMessages } from "react-intl";

const messages = defineMessages({
    all: {
        id: "features.collectionMenu.components.collectionSelectButton.all",
        defaultMessage: "All",
    },
});

export default messages;
