import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
// eslint-disable-next-line import/first
import { useMemo, useCallback } from "react"
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AddDataTableConnectionCriterionInput = {
  dataTableConnectionId: Scalars['String']['input'];
  ignoreOnBlankTarget?: InputMaybe<Scalars['Boolean']['input']>;
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type AddDataTableConnectionCriterionResponse = {
  __typename: 'AddDataTableConnectionCriterionResponse';
  dataTableConnection: DataTableConnection;
};

export type AddOrRemoveFieldsFromDataCubeConfigurationInput = {
  addOrRemove: AddOrRemoveInput;
  dataTableId: Scalars['String']['input'];
  fields: Array<Scalars['String']['input']>;
};

export type AddOrRemoveFieldsFromDataCubeConfigurationResponse = {
  __typename: 'AddOrRemoveFieldsFromDataCubeConfigurationResponse';
  DataCubeConfiguration: DataCubeConfiguration;
};

export type AddOrRemoveInput =
  | 'ADD'
  | 'REMOVE';

export type AddTagValueToColumnInput = {
  dataColumnId: Scalars['String']['input'];
  tagValue: Scalars['String']['input'];
};

export type AddTagValueToColumnResponse = {
  __typename: 'AddTagValueToColumnResponse';
  tag: TagConfiguration;
};

export type AggregatedView = {
  __typename: 'AggregatedView';
  aggregationConfigurations: Array<AggregationConfiguration>;
  filters: Array<AnalysisFilter>;
  groupingConfigurations: Array<GroupingConfiguration>;
  id: Scalars['ID']['output'];
  resultDataTableId: Scalars['String']['output'];
  sourceDataTableId: Scalars['String']['output'];
};

export type AggregationConfiguration = {
  __typename: 'AggregationConfiguration';
  field: Scalars['String']['output'];
  filters: Array<AnalysisFilter>;
  id: Scalars['ID']['output'];
  type: AggregationType;
};

export type AggregationType =
  | 'AGGREGATION_AVERAGE'
  | 'AGGREGATION_COUNT'
  | 'AGGREGATION_COUNT_UNIQUE'
  | 'AGGREGATION_MAXIMUM'
  | 'AGGREGATION_MINIMUM'
  | 'AGGREGATION_SUM';

export type AggregationTypeInput =
  | 'AGGREGATION_AVERAGE'
  | 'AGGREGATION_COUNT'
  | 'AGGREGATION_COUNT_UNIQUE'
  | 'AGGREGATION_MAXIMUM'
  | 'AGGREGATION_MINIMUM'
  | 'AGGREGATION_SUM';

export type AnalysisFilter = {
  __typename: 'AnalysisFilter';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  exclude: Array<Scalars['String']['output']>;
  excludeBlanks?: Maybe<Scalars['Boolean']['output']>;
  exists?: Maybe<Scalars['Boolean']['output']>;
  field: Scalars['String']['output'];
  filterType: Scalars['String']['output'];
  include: Array<Scalars['String']['output']>;
  includeBlanks?: Maybe<Scalars['Boolean']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  periodLength?: Maybe<Scalars['Int']['output']>;
  periodUnit?: Maybe<Scalars['String']['output']>;
  searchTerm?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type AnalysisFilterInput = {
  boolean?: InputMaybe<Scalars['Boolean']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  exclude: Array<Scalars['String']['input']>;
  excludeBlanks?: InputMaybe<Scalars['Boolean']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  field: Scalars['String']['input'];
  filterType: Scalars['String']['input'];
  include: Array<Scalars['String']['input']>;
  includeBlanks?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  periodLength?: InputMaybe<Scalars['Int']['input']>;
  periodUnit?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type ApplyDataTableConnectionChangesInput = {
  changedConnectionColumns: Array<Scalars['String']['input']>;
  columnsWithChangedCubeConfiguration: Array<Scalars['String']['input']>;
  createdConnectionColumns: Array<Scalars['String']['input']>;
};

export type ApplyDataTableConnectionChangesResponse = {
  __typename: 'ApplyDataTableConnectionChangesResponse';
  notificationId: Scalars['String']['output'];
};

export type ChangeOperationTypeInput =
  | 'CREATE'
  | 'DELETE'
  | 'UPDATE';

export type CreateAggregatedDataColumnInput = {
  aggregationField: Scalars['String']['input'];
  aggregationType: AggregationTypeInput;
  dataTableId: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  groupByFields: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sourceDataTable: Scalars['String']['input'];
};

export type CreateAggregatedDataColumnResponse = {
  __typename: 'CreateAggregatedDataColumnResponse';
  dataColumn: DataColumn;
};

export type CreateAggregatedViewInput = {
  aggregations: Array<CreateAggregationInput>;
  baseDataTableId: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  groupByFields: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateAggregatedViewResponse = {
  __typename: 'CreateAggregatedViewResponse';
  entity: AggregatedView;
};

export type CreateAggregationInput = {
  field: Scalars['String']['input'];
  filters: Array<AnalysisFilterInput>;
  name: Scalars['String']['input'];
  type: AggregationTypeInput;
};

export type CreateDataColumnInput = {
  dataTableId: Scalars['String']['input'];
  dataType: DataColumnTypeInput;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataColumnPayloadInput = {
  dataType: DataColumnTypeInput;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataColumnResponse = {
  __typename: 'CreateDataColumnResponse';
  dataColumn: DataColumn;
};

export type CreateDataColumnsInput = {
  dataColumns: Array<CreateDataColumnPayloadInput>;
  dataTableId: Scalars['String']['input'];
};

export type CreateDataColumnsResponse = {
  __typename: 'CreateDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type CreateDataRowInput = {
  dataJson: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  metaJson?: InputMaybe<Scalars['String']['input']>;
  rowId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDataRowResponse = {
  __typename: 'CreateDataRowResponse';
  dataTableRow: DataTableRow;
};

export type CreateDataTableCollectionInput = {
  DataTableCollectionId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateDataTableCollectionResponse = {
  __typename: 'CreateDataTableCollectionResponse';
  dataTableCollection: DataTableCollection;
};

export type CreateDataTableConnectionCriterionInputInput = {
  ignoreOnBlankTarget: Scalars['Boolean']['input'];
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type CreateDataTableConnectionInput = {
  criteria: Array<CreateDataTableConnectionCriterionInputInput>;
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  targetDataSourceIdentifier: Scalars['String']['input'];
  targetDataSourceType: DataTableConnectionSourceTypeInput;
};

export type CreateDataTableConnectionResponse = {
  __typename: 'CreateDataTableConnectionResponse';
  dataTableConnection: DataTableConnection;
};

export type CreateDataTableInput = {
  name: Scalars['String']['input'];
};

export type CreateDataTableResponse = {
  __typename: 'CreateDataTableResponse';
  entity: DataTable;
};

export type CreateTagColumnInput = {
  dataTableId: Scalars['String']['input'];
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  tagStringValues: Array<Scalars['String']['input']>;
};

export type CreateTagColumnResponse = {
  __typename: 'CreateTagColumnResponse';
  columnId: Scalars['String']['output'];
  tag: TagConfiguration;
};

export type DataColumn = {
  __typename: 'DataColumn';
  dataTableId: Scalars['String']['output'];
  dataType: DataColumnType;
  externalSourceKey?: Maybe<Scalars['String']['output']>;
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isList: Scalars['Boolean']['output'];
  isProtected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  referencedId?: Maybe<Scalars['String']['output']>;
};

export type DataColumnElasticFieldMap = {
  __typename: 'DataColumnElasticFieldMap';
  dataColumn: DataColumn;
  fields: Array<Scalars['String']['output']>;
};

export type DataColumnSelectionInput =
  | 'INCLUDE_RELATED'
  | 'ONLY_INDEXED';

export type DataColumnType =
  | 'AGGREGATION'
  | 'BOOLEAN'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'EMISSIONS'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NUMBER'
  | 'TABLE_RELATION'
  | 'TAG'
  | 'TEXT'
  | 'USER';

export type DataColumnTypeInput =
  | 'AGGREGATION'
  | 'BOOLEAN'
  | 'COMPANY'
  | 'CONTACT'
  | 'DATE'
  | 'DATE_AGGREGATION'
  | 'EMISSIONS'
  | 'FILE'
  | 'GROUP_STRUCTURE'
  | 'MONETARY_AMOUNT'
  | 'NUMBER'
  | 'TABLE_RELATION'
  | 'TAG'
  | 'TEXT'
  | 'USER';

export type DataCubeConfiguration = {
  __typename: 'DataCubeConfiguration';
  dataTableId: Scalars['String']['output'];
  fieldsToInclude: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type DataTable = {
  __typename: 'DataTable';
  DataTableCollectionId?: Maybe<Scalars['String']['output']>;
  elasticIndex: Scalars['String']['output'];
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  hasPendingConnectionChanges?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameColumnId?: Maybe<Scalars['String']['output']>;
  relativeDateColumnId?: Maybe<Scalars['String']['output']>;
  relativeDateSetting?: Maybe<RelativeDateSetting>;
  tableType?: Maybe<Scalars['String']['output']>;
};

export type DataTableCollection = {
  __typename: 'DataTableCollection';
  id: Scalars['ID']['output'];
  index?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type DataTableCollectionOrderItem = {
  __typename: 'DataTableCollectionOrderItem';
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
};

export type DataTableCollectionOrderItemInput = {
  id: Scalars['ID']['input'];
  index: Scalars['Int']['input'];
};

export type DataTableConnection = {
  __typename: 'DataTableConnection';
  criteria: Array<DataTableConnectionCriterion>;
  dataColumnId: Scalars['String']['output'];
  dataTableId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  targetDataSource: DataTableConnectionSource;
};

export type DataTableConnectionCriterion = {
  __typename: 'DataTableConnectionCriterion';
  id: Scalars['ID']['output'];
  ignoreOnBlankTarget: Scalars['Boolean']['output'];
  sourceField: Scalars['String']['output'];
  targetField: Scalars['String']['output'];
  type: DataTableConnectionCriterionType;
};

export type DataTableConnectionCriterionInput = {
  id: Scalars['ID']['input'];
  ignoreOnBlankTarget: Scalars['Boolean']['input'];
  sourceField: Scalars['String']['input'];
  targetField: Scalars['String']['input'];
  type: DataTableConnectionCriterionTypeInput;
};

export type DataTableConnectionCriterionType =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL';

export type DataTableConnectionCriterionTypeInput =
  | 'EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL';

export type DataTableConnectionInput = {
  criteria: Array<DataTableConnectionCriterionInput>;
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  targetDataSource: DataTableConnectionSourceInput;
};

export type DataTableConnectionSource = {
  __typename: 'DataTableConnectionSource';
  identifier: Scalars['String']['output'];
  type: DataTableConnectionSourceType;
};

export type DataTableConnectionSourceInput = {
  identifier: Scalars['String']['input'];
  type: DataTableConnectionSourceTypeInput;
};

export type DataTableConnectionSourceType =
  | 'DATA_TABLE';

export type DataTableConnectionSourceTypeInput =
  | 'DATA_TABLE';

export type DataTableExport = {
  __typename: 'DataTableExport';
  exportDate: Scalars['String']['output'];
  exportFormat: ExportFormats;
  id: Scalars['ID']['output'];
};

export type DataTableRow = {
  __typename: 'DataTableRow';
  dataJson: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DataTableRowWithLabel = {
  __typename: 'DataTableRowWithLabel';
  dataJsonWithLabel: Scalars['String']['output'];
  departmentId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DeleteDataColumnsInput = {
  dataColumnIds: Array<Scalars['String']['input']>;
  dataTableId: Scalars['String']['input'];
};

export type DeleteDataColumnsResponse = {
  __typename: 'DeleteDataColumnsResponse';
  dataTable: DataTable;
  notificationId: Scalars['String']['output'];
};

export type DeleteDataRowInput = {
  dataTableId: Scalars['String']['input'];
  metaJson?: InputMaybe<Scalars['String']['input']>;
  rowId: Scalars['String']['input'];
};

export type DeleteDataRowResponse = {
  __typename: 'DeleteDataRowResponse';
  deleteId: Scalars['String']['output'];
};

export type DeleteDataTableCollectionInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataTableCollectionResponse = {
  __typename: 'DeleteDataTableCollectionResponse';
  deleteId: Scalars['String']['output'];
};

export type DeleteDataTableConnectionInput = {
  dataTableConnectionId: Scalars['String']['input'];
};

export type DeleteDataTableConnectionResponse = {
  __typename: 'DeleteDataTableConnectionResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteDataTableInput = {
  id: Scalars['String']['input'];
};

export type DeleteDataTableResponse = {
  __typename: 'DeleteDataTableResponse';
  deletedId: Scalars['String']['output'];
};

export type DeleteTagValueByValueInput = {
  dataColumnId: Scalars['String']['input'];
  tagValue: Scalars['String']['input'];
};

export type DeleteTagValueByValueResponse = {
  __typename: 'DeleteTagValueByValueResponse';
  tag: TagConfiguration;
};

export type ElasticFieldItem = {
  __typename: 'ElasticFieldItem';
  elasticIndex: Scalars['String']['output'];
  field: Scalars['String']['output'];
  globalTypeKey?: Maybe<Scalars['String']['output']>;
  globalTypeSubKey?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  labelField?: Maybe<Scalars['String']['output']>;
  labelFieldType?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  maxDateForRelativeFilters?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type EnumElement = {
  __typename: 'EnumElement';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type ExportDataTableToFileInput = {
  dataTableId: Scalars['String']['input'];
  exportFormat: ExportFormatsInput;
  fields: Array<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  notificationTitle?: InputMaybe<Scalars['String']['input']>;
};

export type ExportDataTableToFileResponse = {
  __typename: 'ExportDataTableToFileResponse';
  notificationId: Scalars['String']['output'];
};

export type ExportFormats =
  | 'CSV'
  | 'XLSX';

export type ExportFormatsInput =
  | 'CSV'
  | 'XLSX';

export type GetAllDataColumnsResponse = {
  __typename: 'GetAllDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetAllDataTableCollectionsResponse = {
  __typename: 'GetAllDataTableCollectionsResponse';
  dataTableCollections: Array<DataTableCollection>;
};

export type GetAllDataTableConnectionsResponse = {
  __typename: 'GetAllDataTableConnectionsResponse';
  dataTableConnections: Array<DataTableConnection>;
};

export type GetAllElasticFieldsResponse = {
  __typename: 'GetAllElasticFieldsResponse';
  elasticFields: Array<ElasticFieldItem>;
};

export type GetDataColumnDependenciesInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetDataColumnDependenciesResponse = {
  __typename: 'GetDataColumnDependenciesResponse';
  hasDeps: Scalars['Boolean']['output'];
  messageToUser: Scalars['String']['output'];
};

export type GetDataColumnElasticFieldMapInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataColumnElasticFieldMapResponse = {
  __typename: 'GetDataColumnElasticFieldMapResponse';
  dataColumnElasticFieldMaps: Array<DataColumnElasticFieldMap>;
};

export type GetDataColumnsInput = {
  columnSelection?: InputMaybe<DataColumnSelectionInput>;
  dataTableId: Scalars['String']['input'];
};

export type GetDataColumnsResponse = {
  __typename: 'GetDataColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetDataCubeConfigurationInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataCubeConfigurationResponse = {
  __typename: 'GetDataCubeConfigurationResponse';
  DataCubeConfiguration: DataCubeConfiguration;
};

export type GetDataTableByGlobalTypeInput = {
  globalTypeKey: Scalars['String']['input'];
};

export type GetDataTableByGlobalTypeResponse = {
  __typename: 'GetDataTableByGlobalTypeResponse';
  dataTable?: Maybe<DataTable>;
};

export type GetDataTableElasticFieldsInput = {
  dataTableId: Scalars['String']['input'];
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  includeLabelFields?: InputMaybe<Scalars['Boolean']['input']>;
  withEnrichments: Scalars['Boolean']['input'];
};

export type GetDataTableElasticFieldsResponse = {
  __typename: 'GetDataTableElasticFieldsResponse';
  elasticFields: Array<ElasticFieldItem>;
};

export type GetDataTableFieldLabelsInput = {
  dataTableId: Scalars['String']['input'];
  includePostfix: Scalars['Boolean']['input'];
};

export type GetDataTableFieldLabelsResponse = {
  __typename: 'GetDataTableFieldLabelsResponse';
  fieldLabelsJson: Scalars['String']['output'];
};

export type GetDataTableInput = {
  id: Scalars['String']['input'];
};

export type GetDataTableResponse = {
  __typename: 'GetDataTableResponse';
  entity: DataTable;
};

export type GetDataTableRowCountInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataTableRowCountResponse = {
  __typename: 'GetDataTableRowCountResponse';
  count: Scalars['Int']['output'];
};

export type GetDataTableRowInput = {
  dataTableId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type GetDataTableRowResponse = {
  __typename: 'GetDataTableRowResponse';
  dataTableRow: DataTableRow;
};

export type GetDataTableRowsInput = {
  dataTableId: Scalars['String']['input'];
  rowIds: Array<Scalars['String']['input']>;
};

export type GetDataTableRowsResponse = {
  __typename: 'GetDataTableRowsResponse';
  rows: Array<DataTableRow>;
};

export type GetDataTableRowsSearchInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetDataTableRowsSearchInput;
};

export type GetDataTableRowsSearchResponse = {
  __typename: 'GetDataTableRowsSearchResponse';
  dataTableRows: Array<DataTableRow>;
  total: Scalars['Int']['output'];
};

export type GetDataTableValidationSummaryInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetDataTableValidationSummaryResponse = {
  __typename: 'GetDataTableValidationSummaryResponse';
  cubeConfigurationCount: Scalars['Int']['output'];
  indexFieldCount: Scalars['Int']['output'];
};

export type GetDependantColumnsInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetDependantColumnsResponse = {
  __typename: 'GetDependantColumnsResponse';
  dataColumns: Array<DataColumn>;
};

export type GetExportDownloadUrlsInput = {
  exportId: Scalars['String']['input'];
};

export type GetExportDownloadUrlsResponse = {
  __typename: 'GetExportDownloadUrlsResponse';
  downloadUrls: Array<Scalars['String']['output']>;
};

export type GetExportsByTableIdInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetExportsByTableIdResponse = {
  __typename: 'GetExportsByTableIdResponse';
  exports: Array<DataTableExport>;
};

export type GetManyAggregatedViewsResponse = {
  __typename: 'GetManyAggregatedViewsResponse';
  entities: Array<AggregatedView>;
};

export type GetManyDataTablesResponse = {
  __typename: 'GetManyDataTablesResponse';
  entities: Array<DataTable>;
};

export type GetOrCreateDataTableCollectionOrderResponse = {
  __typename: 'GetOrCreateDataTableCollectionOrderResponse';
  collectionOrder: Array<DataTableCollectionOrderItem>;
  id: Scalars['String']['output'];
};

export type GetRelatedDataColumnsByDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type GetRelatedDataColumnsByDataTableResponse = {
  __typename: 'GetRelatedDataColumnsByDataTableResponse';
  dataColumns: Array<DataColumn>;
};

export type GetRowsSearchWithLabelsInput = {
  index: Scalars['Int']['input'];
  range: Scalars['Int']['input'];
  request: PaginatedGetRowsSearchWithLabelsInput;
};

export type GetRowsSearchWithLabelsResponse = {
  __typename: 'GetRowsSearchWithLabelsResponse';
  dataTableRowsWithLabel: Array<DataTableRowWithLabel>;
  total: Scalars['Int']['output'];
};

export type GetSupplierFieldsMappingSuggestionInput = {
  fileColumns: Array<Scalars['String']['input']>;
  supplierColumns: Array<SupplierColumnInput>;
};

export type GetSupplierFieldsMappingSuggestionResponse = {
  __typename: 'GetSupplierFieldsMappingSuggestionResponse';
  suggestions: Array<MappingSuggestion>;
};

export type GetTagValuesByColumnIdInput = {
  dataColumnId: Scalars['String']['input'];
};

export type GetTagValuesByColumnIdResponse = {
  __typename: 'GetTagValuesByColumnIdResponse';
  tagValues: Array<EnumElement>;
};

export type GroupingConfiguration = {
  __typename: 'GroupingConfiguration';
  dataColumnId: Scalars['String']['output'];
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type IndexDataTableToElasticInput = {
  dataTableId: Scalars['String']['input'];
};

export type IndexDataTableToElasticResponse = {
  __typename: 'IndexDataTableToElasticResponse';
  notificationId: Scalars['String']['output'];
};

export type MappingSuggestion = {
  __typename: 'MappingSuggestion';
  columnId: Scalars['String']['output'];
  fileColumn: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  addDataTableConnectionCriterion: AddDataTableConnectionCriterionResponse;
  addOrRemoveFieldsFromDataCubeConfiguration: AddOrRemoveFieldsFromDataCubeConfigurationResponse;
  addTagValueToColumn: AddTagValueToColumnResponse;
  applyDataTableConnectionChanges: ApplyDataTableConnectionChangesResponse;
  createAggregatedDataColumn: CreateAggregatedDataColumnResponse;
  createAggregatedView: CreateAggregatedViewResponse;
  createDataColumn: CreateDataColumnResponse;
  createDataColumns: CreateDataColumnsResponse;
  createDataRow: CreateDataRowResponse;
  createDataTable: CreateDataTableResponse;
  createDataTableCollection: CreateDataTableCollectionResponse;
  createDataTableConnection: CreateDataTableConnectionResponse;
  createTagColumn: CreateTagColumnResponse;
  deleteDataColumns: DeleteDataColumnsResponse;
  deleteDataRow: DeleteDataRowResponse;
  deleteDataTable: DeleteDataTableResponse;
  deleteDataTableCollection: DeleteDataTableCollectionResponse;
  deleteDataTableConnection: DeleteDataTableConnectionResponse;
  deleteTagValueByValue: DeleteTagValueByValueResponse;
  exportDataTableToFile: ExportDataTableToFileResponse;
  indexDataTableToElastic: IndexDataTableToElasticResponse;
  optimizeDataTable: OptimizeDataTableResponse;
  reapplyDataTableConnections: ReapplyDataTableConnectionsResponse;
  removeDataTableConnectionCriterion: RemoveDataTableConnectionCriterionResponse;
  removeGhostRows: RemoveGhostRowsResponse;
  replaceDataColumnWithNewDataType: ReplaceDataColumnWithNewDataTypeResponse;
  startAggregatedView: StartAggregatedViewResponse;
  startDefaultSetup: StartDefaultSetupResponse;
  testDataTableConnection: TestDataTableConnectionResponse;
  updateAggregatedView: UpdateAggregatedViewResponse;
  updateDataColumn: UpdateDataColumnResponse;
  updateDataTable: UpdateDataTableResponse;
  updateDataTableCollection: UpdateDataTableCollectionResponse;
  updateDataTableCollectionOrder: UpdateDataTableCollectionOrderResponse;
  updateDataTableRow: UpdateDataTableRowResponse;
};


export type MutationAddDataTableConnectionCriterionArgs = {
  input: AddDataTableConnectionCriterionInput;
};


export type MutationAddOrRemoveFieldsFromDataCubeConfigurationArgs = {
  input: AddOrRemoveFieldsFromDataCubeConfigurationInput;
};


export type MutationAddTagValueToColumnArgs = {
  input: AddTagValueToColumnInput;
};


export type MutationApplyDataTableConnectionChangesArgs = {
  input: ApplyDataTableConnectionChangesInput;
};


export type MutationCreateAggregatedDataColumnArgs = {
  input: CreateAggregatedDataColumnInput;
};


export type MutationCreateAggregatedViewArgs = {
  input: CreateAggregatedViewInput;
};


export type MutationCreateDataColumnArgs = {
  input: CreateDataColumnInput;
};


export type MutationCreateDataColumnsArgs = {
  input: CreateDataColumnsInput;
};


export type MutationCreateDataRowArgs = {
  input: CreateDataRowInput;
};


export type MutationCreateDataTableArgs = {
  input: CreateDataTableInput;
};


export type MutationCreateDataTableCollectionArgs = {
  input: CreateDataTableCollectionInput;
};


export type MutationCreateDataTableConnectionArgs = {
  input: CreateDataTableConnectionInput;
};


export type MutationCreateTagColumnArgs = {
  input: CreateTagColumnInput;
};


export type MutationDeleteDataColumnsArgs = {
  input: DeleteDataColumnsInput;
};


export type MutationDeleteDataRowArgs = {
  input: DeleteDataRowInput;
};


export type MutationDeleteDataTableArgs = {
  input: DeleteDataTableInput;
};


export type MutationDeleteDataTableCollectionArgs = {
  input: DeleteDataTableCollectionInput;
};


export type MutationDeleteDataTableConnectionArgs = {
  input: DeleteDataTableConnectionInput;
};


export type MutationDeleteTagValueByValueArgs = {
  input: DeleteTagValueByValueInput;
};


export type MutationExportDataTableToFileArgs = {
  input: ExportDataTableToFileInput;
};


export type MutationIndexDataTableToElasticArgs = {
  input: IndexDataTableToElasticInput;
};


export type MutationOptimizeDataTableArgs = {
  input: OptimizeDataTableInput;
};


export type MutationReapplyDataTableConnectionsArgs = {
  input: ReapplyDataTableConnectionsInput;
};


export type MutationRemoveDataTableConnectionCriterionArgs = {
  input: RemoveDataTableConnectionCriterionInput;
};


export type MutationRemoveGhostRowsArgs = {
  input: RemoveGhostRowsInput;
};


export type MutationReplaceDataColumnWithNewDataTypeArgs = {
  input: ReplaceDataColumnWithNewDataTypeInput;
};


export type MutationStartAggregatedViewArgs = {
  input: StartAggregatedViewInput;
};


export type MutationStartDefaultSetupArgs = {
  input: StartDefaultSetupInput;
};


export type MutationTestDataTableConnectionArgs = {
  input: TestDataTableConnectionInput;
};


export type MutationUpdateAggregatedViewArgs = {
  input: UpdateAggregatedViewInput;
};


export type MutationUpdateDataColumnArgs = {
  input: UpdateDataColumnInput;
};


export type MutationUpdateDataTableArgs = {
  input: UpdateDataTableInput;
};


export type MutationUpdateDataTableCollectionArgs = {
  input: UpdateDataTableCollectionInput;
};


export type MutationUpdateDataTableCollectionOrderArgs = {
  input: UpdateDataTableCollectionOrderInput;
};


export type MutationUpdateDataTableRowArgs = {
  input: UpdateDataTableRowInput;
};

export type OptimizeDataTableInput = {
  dataTableId: Scalars['String']['input'];
};

export type OptimizeDataTableResponse = {
  __typename: 'OptimizeDataTableResponse';
  notificationId: Scalars['String']['output'];
};

export type PaginatedGetDataTableRowsSearchInput = {
  dataTableId: Scalars['String']['input'];
  filterJSON?: InputMaybe<Scalars['String']['input']>;
  sortingArray: Array<SortingArrayInput>;
};

export type PaginatedGetRowsSearchWithLabelsInput = {
  dataTableId: Scalars['String']['input'];
  filterJSON?: InputMaybe<Scalars['String']['input']>;
  sortingArray: Array<SortingArrayInput>;
};

export type Query = {
  __typename: 'Query';
  getAllDataColumns: GetAllDataColumnsResponse;
  getAllDataTableCollections: GetAllDataTableCollectionsResponse;
  getAllDataTableConnections: GetAllDataTableConnectionsResponse;
  getAllElasticFields: GetAllElasticFieldsResponse;
  getDataColumnDependencies: GetDataColumnDependenciesResponse;
  getDataColumnElasticFieldMap: GetDataColumnElasticFieldMapResponse;
  getDataColumns: GetDataColumnsResponse;
  getDataCubeConfiguration: GetDataCubeConfigurationResponse;
  getDataTable: GetDataTableResponse;
  getDataTableByGlobalType: GetDataTableByGlobalTypeResponse;
  getDataTableElasticFields: GetDataTableElasticFieldsResponse;
  getDataTableFieldLabels: GetDataTableFieldLabelsResponse;
  getDataTableRow: GetDataTableRowResponse;
  getDataTableRowCount: GetDataTableRowCountResponse;
  getDataTableRows: GetDataTableRowsResponse;
  getDataTableRowsSearch: GetDataTableRowsSearchResponse;
  getDataTableValidationSummary: GetDataTableValidationSummaryResponse;
  getDependantColumns: GetDependantColumnsResponse;
  getExportDownloadUrls: GetExportDownloadUrlsResponse;
  getExportsByTableId: GetExportsByTableIdResponse;
  getManyAggregatedViews: GetManyAggregatedViewsResponse;
  getManyDataTables: GetManyDataTablesResponse;
  getOrCreateDataTableCollectionOrder: GetOrCreateDataTableCollectionOrderResponse;
  getRelatedDataColumnsByDataTable: GetRelatedDataColumnsByDataTableResponse;
  getRowsSearchWithLabels: GetRowsSearchWithLabelsResponse;
  getSupplierFieldsMappingSuggestion: GetSupplierFieldsMappingSuggestionResponse;
  getTagValuesByColumnId: GetTagValuesByColumnIdResponse;
};


export type QueryGetDataColumnDependenciesArgs = {
  input: GetDataColumnDependenciesInput;
};


export type QueryGetDataColumnElasticFieldMapArgs = {
  input: GetDataColumnElasticFieldMapInput;
};


export type QueryGetDataColumnsArgs = {
  input: GetDataColumnsInput;
};


export type QueryGetDataCubeConfigurationArgs = {
  input: GetDataCubeConfigurationInput;
};


export type QueryGetDataTableArgs = {
  input: GetDataTableInput;
};


export type QueryGetDataTableByGlobalTypeArgs = {
  input: GetDataTableByGlobalTypeInput;
};


export type QueryGetDataTableElasticFieldsArgs = {
  input: GetDataTableElasticFieldsInput;
};


export type QueryGetDataTableFieldLabelsArgs = {
  input: GetDataTableFieldLabelsInput;
};


export type QueryGetDataTableRowArgs = {
  input: GetDataTableRowInput;
};


export type QueryGetDataTableRowCountArgs = {
  input: GetDataTableRowCountInput;
};


export type QueryGetDataTableRowsArgs = {
  input: GetDataTableRowsInput;
};


export type QueryGetDataTableRowsSearchArgs = {
  input: GetDataTableRowsSearchInput;
};


export type QueryGetDataTableValidationSummaryArgs = {
  input: GetDataTableValidationSummaryInput;
};


export type QueryGetDependantColumnsArgs = {
  input: GetDependantColumnsInput;
};


export type QueryGetExportDownloadUrlsArgs = {
  input: GetExportDownloadUrlsInput;
};


export type QueryGetExportsByTableIdArgs = {
  input: GetExportsByTableIdInput;
};


export type QueryGetRelatedDataColumnsByDataTableArgs = {
  input: GetRelatedDataColumnsByDataTableInput;
};


export type QueryGetRowsSearchWithLabelsArgs = {
  input: GetRowsSearchWithLabelsInput;
};


export type QueryGetSupplierFieldsMappingSuggestionArgs = {
  input: GetSupplierFieldsMappingSuggestionInput;
};


export type QueryGetTagValuesByColumnIdArgs = {
  input: GetTagValuesByColumnIdInput;
};

export type ReapplyDataTableConnectionsInput = {
  dataColumns: Array<Scalars['String']['input']>;
  dataTableId: Scalars['String']['input'];
};

export type ReapplyDataTableConnectionsResponse = {
  __typename: 'ReapplyDataTableConnectionsResponse';
  notificationId: Scalars['String']['output'];
};

export type RelativeDateSetting =
  | 'MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN'
  | 'MAX_DATE_COLUMN'
  | 'MAX_DATE_ON_SPECIFIC_COLUMN'
  | 'TODAY';

export type RelativeDateSettingInput =
  | 'MAX_DATE_BEFORE_TODAY_SPECIFIC_COLUMN'
  | 'MAX_DATE_COLUMN'
  | 'MAX_DATE_ON_SPECIFIC_COLUMN'
  | 'TODAY';

export type RemoveDataTableConnectionCriterionInput = {
  dataTableConnectionCriterionId: Scalars['String']['input'];
  dataTableConnectionId: Scalars['String']['input'];
};

export type RemoveDataTableConnectionCriterionResponse = {
  __typename: 'RemoveDataTableConnectionCriterionResponse';
  dataTableConnection: DataTableConnection;
};

export type RemoveGhostRowsInput = {
  dataTableId: Scalars['String']['input'];
};

export type RemoveGhostRowsResponse = {
  __typename: 'RemoveGhostRowsResponse';
  notificationId: Scalars['String']['output'];
};

export type ReplaceDataColumnWithNewDataTypeInput = {
  dataColumnId: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  newDataType: DataColumnTypeInput;
  toList: Scalars['Boolean']['input'];
};

export type ReplaceDataColumnWithNewDataTypeResponse = {
  __typename: 'ReplaceDataColumnWithNewDataTypeResponse';
  newDataColumn: DataColumn;
  notificationIds: Array<Scalars['String']['output']>;
};

export type SortDirectionsInput =
  | 'asc'
  | 'desc';

export type SortingArrayInput = {
  dataColumnId: Scalars['String']['input'];
  order: SortDirectionsInput;
};

export type SpecificationInput =
  | 'ACCENTURE';

export type StartAggregatedViewInput = {
  id: Scalars['String']['input'];
};

export type StartAggregatedViewResponse = {
  __typename: 'StartAggregatedViewResponse';
  notificationId: Scalars['String']['output'];
};

export type StartDefaultSetupInput = {
  relate: Scalars['Boolean']['input'];
  specification: SpecificationInput;
};

export type StartDefaultSetupResponse = {
  __typename: 'StartDefaultSetupResponse';
  success: Scalars['Boolean']['output'];
};

export type SupplierColumnInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type TagConfiguration = {
  __typename: 'TagConfiguration';
  enumValues: Array<EnumElement>;
  id: Scalars['ID']['output'];
};

export type TestDataTableConnectionInput = {
  dataTableConnection: DataTableConnectionInput;
  filtersJSON: Scalars['String']['input'];
};

export type TestDataTableConnectionResponse = {
  __typename: 'TestDataTableConnectionResponse';
  baseTableRowsWithMatch: Scalars['Int']['output'];
  matchesJSON: Scalars['String']['output'];
  numberOfTargetRowsTested: Scalars['Int']['output'];
  targetRowsWithNoMatch: Scalars['Int']['output'];
};

export type UpdatableAggregatedViewFieldsInput = {
  aggregationsToAdd: Array<CreateAggregationInput>;
  aggregationsToDelete: Array<Scalars['String']['input']>;
  filters: Array<AnalysisFilterInput>;
  groupByFieldsToAdd: Array<Scalars['String']['input']>;
  groupByFieldsToDelete: Array<Scalars['String']['input']>;
};

export type UpdatableDataTableFieldsInput = {
  dataTableCollectionId?: InputMaybe<Scalars['String']['input']>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameColumnId?: InputMaybe<Scalars['String']['input']>;
  relativeDateColumnId?: InputMaybe<Scalars['String']['input']>;
  relativeDateSetting?: InputMaybe<RelativeDateSettingInput>;
};

export type UpdateAggregatedViewInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableAggregatedViewFieldsInput;
};

export type UpdateAggregatedViewResponse = {
  __typename: 'UpdateAggregatedViewResponse';
  entity: AggregatedView;
};

export type UpdateDataColumnInput = {
  dataTableId: Scalars['String']['input'];
  dataType?: InputMaybe<DataColumnTypeInput>;
  globalTypeKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  referencedId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDataColumnResponse = {
  __typename: 'UpdateDataColumnResponse';
  dataColumn: DataColumn;
};

export type UpdateDataTableCollectionInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateDataTableCollectionOrderInput = {
  collectionOrder: Array<DataTableCollectionOrderItemInput>;
};

export type UpdateDataTableCollectionOrderResponse = {
  __typename: 'UpdateDataTableCollectionOrderResponse';
  collectionOrder: Array<DataTableCollectionOrderItem>;
  id: Scalars['String']['output'];
};

export type UpdateDataTableCollectionResponse = {
  __typename: 'UpdateDataTableCollectionResponse';
  dataTableCollection: DataTableCollection;
};

export type UpdateDataTableInput = {
  id: Scalars['String']['input'];
  mask: Array<Scalars['String']['input']>;
  update: UpdatableDataTableFieldsInput;
};

export type UpdateDataTableResponse = {
  __typename: 'UpdateDataTableResponse';
  entity: DataTable;
};

export type UpdateDataTableRowInput = {
  data: Scalars['String']['input'];
  dataTableId: Scalars['String']['input'];
  rowId?: InputMaybe<Scalars['String']['input']>;
  type: ChangeOperationTypeInput;
};

export type UpdateDataTableRowResponse = {
  __typename: 'UpdateDataTableRowResponse';
  id: Scalars['String']['output'];
};

export type DataTableFieldsFragment = { __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, elasticIndex: string };

export type DataColumnFieldsFragment = { __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean, isProtected: boolean };

export type ElasticFieldItemFieldsFragment = { __typename: 'ElasticFieldItem', field: string, label: string, type: string, globalTypeKey?: string | null, globalTypeSubKey?: string | null, labelField?: string | null, labelFieldType?: string | null, max?: number | null, min?: number | null, maxDateForRelativeFilters?: number | null, elasticIndex: string };

export type GetDataTableElasticFieldsQueryVariables = Exact<{
  input: GetDataTableElasticFieldsInput;
}>;


export type GetDataTableElasticFieldsQuery = { __typename: 'Query', getDataTableElasticFields: { __typename: 'GetDataTableElasticFieldsResponse', elasticFields: Array<{ __typename: 'ElasticFieldItem', field: string, label: string, type: string, globalTypeKey?: string | null, globalTypeSubKey?: string | null, labelField?: string | null, labelFieldType?: string | null, max?: number | null, min?: number | null, maxDateForRelativeFilters?: number | null, elasticIndex: string }> } };

export type GetManyDataTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManyDataTablesQuery = { __typename: 'Query', getManyDataTables: { __typename: 'GetManyDataTablesResponse', entities: Array<{ __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, elasticIndex: string }> } };

export type UpdateDataTableMutationVariables = Exact<{
  input: UpdateDataTableInput;
}>;


export type UpdateDataTableMutation = { __typename: 'Mutation', updateDataTable: { __typename: 'UpdateDataTableResponse', entity: { __typename: 'DataTable', id: string, name: string, globalTypeKey?: string | null, elasticIndex: string } } };

export type GetDataColumnsQueryVariables = Exact<{
  input: GetDataColumnsInput;
}>;


export type GetDataColumnsQuery = { __typename: 'Query', getDataColumns: { __typename: 'GetDataColumnsResponse', dataColumns: Array<{ __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean, isProtected: boolean }> } };

export type UpdateDataColumnMutationVariables = Exact<{
  input: UpdateDataColumnInput;
}>;


export type UpdateDataColumnMutation = { __typename: 'Mutation', updateDataColumn: { __typename: 'UpdateDataColumnResponse', dataColumn: { __typename: 'DataColumn', id: string, name: string, dataType: DataColumnType, dataTableId: string, globalTypeKey?: string | null, referencedId?: string | null, isList: boolean, isProtected: boolean } } };

export const DataTableFieldsFragmentDoc = gql`
    fragment DataTableFields on DataTable {
  id
  name
  globalTypeKey
  elasticIndex
}
    `;
export const DataColumnFieldsFragmentDoc = gql`
    fragment DataColumnFields on DataColumn {
  id
  name
  dataType
  dataTableId
  globalTypeKey
  referencedId
  isList
  isProtected
}
    `;
export const ElasticFieldItemFieldsFragmentDoc = gql`
    fragment ElasticFieldItemFields on ElasticFieldItem {
  field
  label
  type
  globalTypeKey
  globalTypeSubKey
  labelField
  labelFieldType
  max
  min
  maxDateForRelativeFilters
  elasticIndex
}
    `;
export const GetDataTableElasticFieldsDocument = gql`
    query getDataTableElasticFields($input: GetDataTableElasticFieldsInput!) {
  getDataTableElasticFields(input: $input) {
    elasticFields {
      ...ElasticFieldItemFields
    }
  }
}
    ${ElasticFieldItemFieldsFragmentDoc}`;
export type GetDataTableElasticFieldsQueryResult = Apollo.QueryResult<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>;
export const GetManyDataTablesDocument = gql`
    query getManyDataTables {
  getManyDataTables {
    entities {
      ...DataTableFields
    }
  }
}
    ${DataTableFieldsFragmentDoc}`;
export type GetManyDataTablesQueryResult = Apollo.QueryResult<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>;
export const UpdateDataTableDocument = gql`
    mutation updateDataTable($input: UpdateDataTableInput!) {
  updateDataTable(input: $input) {
    entity {
      ...DataTableFields
    }
  }
}
    ${DataTableFieldsFragmentDoc}`;
export type UpdateDataTableMutationFn = Apollo.MutationFunction<UpdateDataTableMutation, UpdateDataTableMutationVariables>;
export type UpdateDataTableMutationResult = Apollo.MutationResult<UpdateDataTableMutation>;
export type UpdateDataTableMutationOptions = Apollo.BaseMutationOptions<UpdateDataTableMutation, UpdateDataTableMutationVariables>;
export const GetDataColumnsDocument = gql`
    query getDataColumns($input: GetDataColumnsInput!) {
  getDataColumns(input: $input) {
    dataColumns {
      ...DataColumnFields
    }
  }
}
    ${DataColumnFieldsFragmentDoc}`;
export type GetDataColumnsQueryResult = Apollo.QueryResult<GetDataColumnsQuery, GetDataColumnsQueryVariables>;
export const UpdateDataColumnDocument = gql`
    mutation updateDataColumn($input: UpdateDataColumnInput!) {
  updateDataColumn(input: $input) {
    dataColumn {
      ...DataColumnFields
    }
  }
}
    ${DataColumnFieldsFragmentDoc}`;
export type UpdateDataColumnMutationFn = Apollo.MutationFunction<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>;
export type UpdateDataColumnMutationResult = Apollo.MutationResult<UpdateDataColumnMutation>;
export type UpdateDataColumnMutationOptions = Apollo.BaseMutationOptions<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>;

export type GraphqlAsyncData<ResultType> =
    | { type: "not-asked" }
    | { type: "success"; data: ResultType }
    | { type: "error", error: Apollo.ApolloError }
    | { type: "loading" };
function queryResultToGraphqlAsyncData<
    ResultType,
    Key extends keyof ResultType
>(queryKey: Key, loading: boolean, data?: ResultType | null, error?: Apollo.ApolloError): GraphqlAsyncData<ResultType[Key]> {
    if (loading) {
        return { type: "loading" };
    }
    if (error) {
        return { type: "error", error };
    }
    if (data) {
        return { type: "success", data: data[queryKey] };
    }
    return { type: "not-asked" };
}

type GetDataTableElasticFieldsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataTableElasticFieldsQuery["getDataTableElasticFields"]>;
    refetch: (variables?: Partial<GetDataTableElasticFieldsQueryVariables>) => void;
}
export function useGetDataTableElasticFieldsQuery(
    variables: GetDataTableElasticFieldsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>, 'variables'> = {}
): GetDataTableElasticFieldsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>(GetDataTableElasticFieldsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableElasticFields", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataTableElasticFieldsLazyQueryHookReturnValue = [
    (variables: GetDataTableElasticFieldsQueryVariables) => Promise<Apollo.QueryResult<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>>,
    { result: GraphqlAsyncData<GetDataTableElasticFieldsQuery["getDataTableElasticFields"]>, refetch: (variables: GetDataTableElasticFieldsQueryVariables) => Promise<Apollo.QueryResult<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataTableElasticFieldsQuery>> }
]
export function useGetDataTableElasticFieldsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>, 'variables'> = {}
): GetDataTableElasticFieldsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataTableElasticFieldsQuery, GetDataTableElasticFieldsQueryVariables>(GetDataTableElasticFieldsDocument, options);
    return [
        useCallback((variables: GetDataTableElasticFieldsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataTableElasticFields", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataTableElasticFieldsQueryVariables) => query.refetch(variables)
                : (variables: GetDataTableElasticFieldsQueryVariables) => action({ variables })
        }
    ];
}
type GetManyDataTablesQueryHookReturnValue = {
    result: GraphqlAsyncData<GetManyDataTablesQuery["getManyDataTables"]>;
    refetch: (variables?: Partial<GetManyDataTablesQueryVariables>) => void;
}
export function useGetManyDataTablesQuery(
    variables: GetManyDataTablesQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>, 'variables'> = {}
): GetManyDataTablesQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>(GetManyDataTablesDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getManyDataTables", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetManyDataTablesLazyQueryHookReturnValue = [
    (variables: GetManyDataTablesQueryVariables) => Promise<Apollo.QueryResult<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>>,
    { result: GraphqlAsyncData<GetManyDataTablesQuery["getManyDataTables"]>, refetch: (variables: GetManyDataTablesQueryVariables) => Promise<Apollo.QueryResult<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetManyDataTablesQuery>> }
]
export function useGetManyDataTablesLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>, 'variables'> = {}
): GetManyDataTablesLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetManyDataTablesQuery, GetManyDataTablesQueryVariables>(GetManyDataTablesDocument, options);
    return [
        useCallback((variables: GetManyDataTablesQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getManyDataTables", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetManyDataTablesQueryVariables) => query.refetch(variables)
                : (variables: GetManyDataTablesQueryVariables) => action({ variables })
        }
    ];
}
type UpdateDataTableMutationHookReturnValue = [
    (variables: UpdateDataTableMutationVariables) => Promise<Apollo.FetchResult<UpdateDataTableMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateDataTableMutation["updateDataTable"]> }
]
export function useUpdateDataTableMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateDataTableMutation, UpdateDataTableMutationVariables>, 'variables'> = {}
): UpdateDataTableMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateDataTableMutation, UpdateDataTableMutationVariables>(UpdateDataTableDocument, options)
    return [
        useMemo(() => (variables: UpdateDataTableMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateDataTableMutation, UpdateDataTableMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateDataTable", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}
type GetDataColumnsQueryHookReturnValue = {
    result: GraphqlAsyncData<GetDataColumnsQuery["getDataColumns"]>;
    refetch: (variables?: Partial<GetDataColumnsQueryVariables>) => void;
}
export function useGetDataColumnsQuery(
    variables: GetDataColumnsQueryVariables,
    options: Omit<Apollo.QueryHookOptions<GetDataColumnsQuery, GetDataColumnsQueryVariables>, 'variables'> = {}
): GetDataColumnsQueryHookReturnValue {
    const mergedOptions = { ...options, variables };
    const query = Apollo.useQuery<GetDataColumnsQuery, GetDataColumnsQueryVariables>(GetDataColumnsDocument, mergedOptions);
    return {
        result: useMemo(() => queryResultToGraphqlAsyncData("getDataColumns", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
        refetch: query.refetch
    };
}
type GetDataColumnsLazyQueryHookReturnValue = [
    (variables: GetDataColumnsQueryVariables) => Promise<Apollo.QueryResult<GetDataColumnsQuery, GetDataColumnsQueryVariables>>,
    { result: GraphqlAsyncData<GetDataColumnsQuery["getDataColumns"]>, refetch: (variables: GetDataColumnsQueryVariables) => Promise<Apollo.QueryResult<GetDataColumnsQuery, GetDataColumnsQueryVariables>> | Promise<Apollo.ApolloQueryResult<GetDataColumnsQuery>> }
]
export function useGetDataColumnsLazyQuery(
    options: Omit<Apollo.LazyQueryHookOptions<GetDataColumnsQuery, GetDataColumnsQueryVariables>, 'variables'> = {}
): GetDataColumnsLazyQueryHookReturnValue {
    const [action, query] = Apollo.useLazyQuery<GetDataColumnsQuery, GetDataColumnsQueryVariables>(GetDataColumnsDocument, options);
    return [
        useCallback((variables: GetDataColumnsQueryVariables) => action({ variables }), [action]),
        {
            result: useMemo(() => queryResultToGraphqlAsyncData("getDataColumns", query.loading, query.data, query.error), [query.data, query.loading, query.error]),
            refetch: query.called
                ? (variables: GetDataColumnsQueryVariables) => query.refetch(variables)
                : (variables: GetDataColumnsQueryVariables) => action({ variables })
        }
    ];
}
type UpdateDataColumnMutationHookReturnValue = [
    (variables: UpdateDataColumnMutationVariables) => Promise<Apollo.FetchResult<UpdateDataColumnMutation, Record<string, any>, Record<string, any>>>,
    { result: GraphqlAsyncData<UpdateDataColumnMutation["updateDataColumn"]> }
]
export function useUpdateDataColumnMutation(
    options: Omit<Apollo.MutationHookOptions<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>, 'variables'> = {}
): UpdateDataColumnMutationHookReturnValue {
    const [action, mutation] = Apollo.useMutation<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>(UpdateDataColumnDocument, options)
    return [
        useMemo(() => (variables: UpdateDataColumnMutationVariables, additionalOptions: Omit<Apollo.MutationHookOptions<UpdateDataColumnMutation, UpdateDataColumnMutationVariables>, 'variables'> = {}) => action({ ...options, ...additionalOptions, variables }), []),
        useMemo(() => ({ result: queryResultToGraphqlAsyncData("updateDataColumn", mutation.loading, mutation.data, mutation.error) }), [mutation]),
    ];
}