import { Stringish } from "@ignite-analytics/general-tools";
import { Alert, Box, Button, Divider, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";

import { ImageUpload } from "@/components/ImageUpload";
import { fm } from "@/providers/IntlContextProvider";
import { DashboardLibraryItemData } from "@/types/libraryItem";

import { backgroundSchema } from "../helpers";
import messages from "../messages";

import { AccentureDashboardLibraryCollection, DashboardLibraryCollection } from "./FetchLibraryCollections";

interface Props {
    newLibraryItem: DashboardLibraryItemData;
    setNewLibraryItem: React.Dispatch<React.SetStateAction<DashboardLibraryItemData>>;
    onBack: () => void;
    onNext: () => void;
    isAccenture: boolean;
}

export const BackgroundStep: React.FC<Props> = ({ newLibraryItem, setNewLibraryItem, onBack, onNext, isAccenture }) => {
    const validationSchema = React.useMemo(() => backgroundSchema(), []);
    const [error, setError] = useState<Stringish>();

    const { title, description, collections, helpCenterLink } = newLibraryItem;
    return (
        <Box>
            <Formik
                onSubmit={(formData) => {
                    if (newLibraryItem.coverimage && !error) {
                        setNewLibraryItem((prevState) => ({ ...prevState, ...formData }));
                        onNext();
                    } else if (!error) {
                        setError(fm(messages.noCoverimageError));
                    }
                }}
                validationSchema={validationSchema}
                initialValues={{ title, description, collections, helpCenterLink }}
                enableReinitialize
            >
                {(formikProps) => {
                    return (
                        <Form
                            style={{
                                width: "100%",
                                overflowY: "auto",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Stack direction="row" py={2} gap={2}>
                                <Stack rowGap={1}>
                                    <TextField
                                        name="title"
                                        label={fm(messages.title)}
                                        variant="outlined"
                                        fullWidth
                                        required
                                        value={formikProps.values.title}
                                        onChange={formikProps.handleChange}
                                        error={!!formikProps.errors.title}
                                    />
                                    <FormHelperText error>{formikProps.errors.title}</FormHelperText>
                                    <TextField
                                        name="description"
                                        label={fm(messages.description).toString()}
                                        onChange={formikProps.handleChange}
                                        value={formikProps.values.description}
                                        fullWidth
                                        minRows={1}
                                        maxRows={10}
                                        multiline
                                    />
                                    <FormHelperText error>{formikProps.errors.description}</FormHelperText>
                                </Stack>
                                <Stack rowGap={1}>
                                    <Stack direction="row">
                                        <TextField
                                            label={fm(messages.linkToHelpCenter)}
                                            type="url"
                                            name="helpCenterLink"
                                            fullWidth
                                            value={formikProps.values.helpCenterLink}
                                            onChange={formikProps.handleChange}
                                            placeholder={fm(messages.linkPlaceholder).toString()}
                                        />
                                    </Stack>
                                    <FormHelperText error>{formikProps.errors.helpCenterLink}</FormHelperText>
                                    <Stack direction="row">
                                        {isAccenture ? (
                                            <AccentureDashboardLibraryCollection
                                                selectedCollections={formikProps.values.collections}
                                                onChange={(collectionIds) =>
                                                    formikProps.setFieldValue("collections", collectionIds)
                                                }
                                            />
                                        ) : (
                                            <DashboardLibraryCollection
                                                selectedCollections={formikProps.values.collections}
                                                onChange={(collectionIds) =>
                                                    formikProps.setFieldValue("collections", collectionIds)
                                                }
                                            />
                                        )}
                                    </Stack>
                                    <FormHelperText error>{formikProps.errors.collections}</FormHelperText>
                                </Stack>
                            </Stack>
                            <Divider />
                            <Stack direction="row" py={2}>
                                <Stack>
                                    <ImageUpload
                                        file={newLibraryItem.coverimage?.[0]}
                                        onFileSelected={(file) => {
                                            if (file && ["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
                                                setError(undefined);
                                                setNewLibraryItem((prevState) => ({
                                                    ...prevState,
                                                    coverimage: [file],
                                                }));
                                            } else if (file) {
                                                setError(fm(messages.fileType));
                                            } else {
                                                setError(fm(messages.noCoverimageError));
                                                setNewLibraryItem((prevState) => ({
                                                    ...prevState,
                                                    coverimage: undefined,
                                                }));
                                            }
                                        }}
                                    />
                                </Stack>
                            </Stack>
                            {error && (
                                <Stack direction="row">
                                    <Alert color="warning">
                                        <Typography variant="body2">{error.toString()}</Typography>
                                    </Alert>
                                </Stack>
                            )}
                            <Stack direction="row" gap={1} pt={2} justifyContent="flex-end">
                                <Stack>
                                    <Button color="secondary" onClick={onBack}>
                                        {fm(messages.cancelButton)}
                                    </Button>
                                </Stack>
                                <Stack>
                                    <Button color="primary" type="submit">
                                        {fm(messages.nextButton)}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};
