import { createContext, ReactNode, useState } from "react";

import { InfoMessage, InfoMessageWithTimestamp } from "@/types/infoMessages";

type InfoMessageContextType = {
    infoMessage: InfoMessageWithTimestamp | null;
    informUser: (message: InfoMessage) => void;
    hideInfoMessage: () => void;
};

export const InfoMessageContext = createContext<InfoMessageContextType | undefined>(undefined);

type Props = { children?: ReactNode };

export const InfoMessageProvider: React.FC<Props> = ({ children }) => {
    const [infoMessage, setInfoMessage] = useState<InfoMessageWithTimestamp | null>(null);

    const informUser = (message: InfoMessage) => {
        setInfoMessage({ ...message, timestamp: new Date().getTime() });
    };

    const hideInfoMessage = () => {
        setInfoMessage(null);
    };

    return (
        <InfoMessageContext.Provider value={{ infoMessage, informUser, hideInfoMessage }}>
            {children}
        </InfoMessageContext.Provider>
    );
};
