import { defineMessages } from "react-intl";

const messages = defineMessages({
    noItems: {
        id: "features.libraryOverview.containers.libraryListContainer.noItems",
        defaultMessage: "No items to show.",
    },
    searchResults: {
        id: "features.libraryOverview.containers.libraryListContainer.searchResults",
        defaultMessage: "Search results",
    },
});

export default messages;
