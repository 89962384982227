import { defineMessages } from "react-intl";

const messages = defineMessages({
    configureButton: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.ConfigureButton",
        defaultMessage: "Configure & import",
    },
    skipConfigurationButton: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.skipConfigurationButton",
        defaultMessage: "Skip configuration",
    },
    configModalTitle: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.configModalTitle",
        defaultMessage: "Data settings for {itemName}",
    },
    configModalDescription: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.configModalDescription",
        defaultMessage:
            "To have a pre-filed dashboard with correct data, we need you to configure the following fields.",
    },
    selectYourXTable: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.selectYourXTable",
        defaultMessage: "Select your {globalTypeName} table",
    },
    noDataTableSelected: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.noDataTableSelected",
        defaultMessage: "no data table selected",
    },
    globalTypeTableHeader: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.globalTypeTableHeader",
        defaultMessage: "Global type",
    },
    descriptionTableHeader: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.descriptionTableHeader",
        defaultMessage: "Description",
    },
    dataTypeTableHeader: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.dataTypeTableHeader",
        defaultMessage: "Data type",
    },
    fieldSourceTableHeader: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.fieldSourceTableHeader",
        defaultMessage: "Data column",
    },
    selectSourceField: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.selectSourceField",
        defaultMessage: "Select data column",
    },
    dataTableSourceHeading: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.dataTableSourceHeading",
        defaultMessage: "Select the correct columns for following parameters from your {tableName} table",
    },
    missingGlobalTableTypeError: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.missingGlobalTableTypeError",
        defaultMessage: "Missing global table type",
    },
    skipConfigurationTooltip: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.skipConfigurationTooltip",
        defaultMessage:
            "If you skip configuring global types, you must configure the widgets manually or configure global types in the settings. Global table types have to be configured to continue.",
    },
    cancelButton: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.cancelButton",
        defaultMessage: "Cancel",
    },
    alreadyConfiguredGlobalTypeConfig: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.alreadyConfiguredGlobalTypeConfig",
        defaultMessage: "Global type is already configured, go to 'Data tables' to reconfigure",
    },
    noDescription: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.noDescription",
        defaultMessage: "No description",
    },
    unableToFindField: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.unableToFindField",
        defaultMessage: "Unable to find a field?",
    },
    unableToFindFieldTooltip: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.unableToFindFieldTooltip",
        defaultMessage:
            "If you are unable to find the field you are looking for, it might be because the field is already assigned a global type. Go to 'Data tables' to unassign it's current global type.",
    },
    noFieldsAvailable: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.noFieldsAvailable",
        defaultMessage: "No fields available",
    },
    noFieldsAvailableTooltip: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.noFieldsAvailableTooltip",
        defaultMessage:
            "No fields available for this global type due to all fields of this data type already are assigned to a global type, or you simply have no fields in the table of this data type.",
    },
    failedToImportDashboard: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.failedToImportDashboard",
        defaultMessage: "Failed to import dashboard",
    },
    failedToConfigureGlobalTypes: {
        id: "features.importing.components.configureGlobalTypesDialogForDashboard.failedToConfigureGlobalTypes",
        defaultMessage: "Failed to configure global types",
    },
});

export default messages;
