import { HeartBroken } from "@mui/icons-material";
import { AlertTitle, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { messages } from "./messages";

export const SentryErrorFallback: React.FC = () => {
    const { formatMessage: fm } = useIntl();

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "80vh" }}>
            <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                    <HeartBroken fontSize="large" sx={{ marginBottom: "10px" }} />
                    <AlertTitle>
                        <Typography variant="h5">{fm(messages.error)}</Typography>
                    </AlertTitle>
                </Stack>
                <Typography variant="body1">{fm(messages.somethingWentWrong)}</Typography>
                <Button size="large" onClick={() => window.location.reload()}>
                    <Typography variant="buttonLarge">{fm(messages.refresh)}</Typography>
                </Button>
            </Stack>
        </Stack>
    );
};
