import { ApolloProvider, createGraphqlClient } from "@ignite-analytics/graphql-utilities";
import { getLocale } from "@ignite-analytics/locale";
import { getDesignTokens } from "@ignite-analytics/theme";
import { CssBaseline, ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from "@tanstack/react-query";
import { createBrowserHistory } from "history";
import React, { ReactNode } from "react";
import { Router } from "react-router-dom";

import { EntitiesContextProvider } from "@/entities";
import { BasePathProvider } from "@/providers/BasePathContextProvider";
import { IntlContext } from "@/providers/IntlContextProvider";
import { MicroAppMountValues } from "@/types/app";

import { InfoMessageProvider } from "./InfoMessageProvider";
import { SentryLoggingProvider } from "./SentryLoggingProvider";
import SessionContextProvider from "./sessionContextProvider";

const uri = process.env.REACT_APP_GRAPHQL_ROUTER_URL as string;
const graphqlClient = createGraphqlClient(uri);
const queryClient = new QueryClient();

type Props = {
    children?: ReactNode;
} & MicroAppMountValues;

export const AppProvider: React.FC<Props> = ({
    children,
    sessionContext,
    history = createBrowserHistory(),
    locale = getLocale(),
    theme = "ignite-riddle",
    path,
}) => {
    return (
        <Router history={history}>
            <CssBaseline />
            <BasePathProvider path={path ?? "/"}>
                <ApolloProvider client={graphqlClient}>
                    <ReactQueryClientProvider client={queryClient}>
                        <IntlContext locale={locale}>
                            <MuiThemeProvider theme={createTheme(getDesignTokens(theme, "light"))}>
                                <EntitiesContextProvider>
                                    <SessionContextProvider sessionContext={sessionContext}>
                                        <SentryLoggingProvider>
                                            <InfoMessageProvider>{children}</InfoMessageProvider>
                                        </SentryLoggingProvider>
                                    </SessionContextProvider>
                                </EntitiesContextProvider>
                            </MuiThemeProvider>
                        </IntlContext>
                    </ReactQueryClientProvider>
                </ApolloProvider>
            </BasePathProvider>
        </Router>
    );
};
