import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "@/App";
import { AppProvider } from "@/providers";
import { setupProxyAuth } from "@/setupProxyAuth";

import { MicroAppMountValues } from "./types/app";

let root: ReturnType<typeof createRoot> | null = null;

function renderApp(props: MicroAppMountValues, rootNode = "root") {
    const container = document.getElementById(rootNode);
    if (container) {
        root = createRoot(container);
        root.render(
            <React.StrictMode>
                <AppProvider {...props}>
                    <App />
                </AppProvider>
            </React.StrictMode>
        );
    }
}

window.renderLibrary = (containerId, props: MicroAppMountValues) => {
    renderApp(props, containerId);
};

window.unmountLibrary = () => {
    root?.unmount();
};

// Running locally
function renderAppLocally(props: MicroAppMountValues, rootNode: string) {
    const container = document.getElementById(rootNode) as HTMLElement;
    createRoot(container).render(
        <React.StrictMode>
            <AppProvider {...props}>
                <App />
            </AppProvider>
        </React.StrictMode>
    );
}

if (!document.getElementById("Library-container")) {
    const isProxyToProductionBackend = process.env.REACT_APP_PROXY_WEB_TO_PRODUCTION === "true";
    if (isProxyToProductionBackend) {
        const sessionContext = await setupProxyAuth();
        renderAppLocally({ sessionContext }, "dev-root");
    } else {
        renderAppLocally(
            {
                sessionContext: {
                    id: process.env.REACT_APP_DEFAULT_USER_ID as string,
                    email: process.env.REACT_APP_DEFAULT_USER as string,
                    tenant: process.env.REACT_APP_DEFAULT_TENANT as string,
                },
            },
            "dev-root"
        );
    }
}
