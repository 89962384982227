import { Card, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";

import { IMAGE_SOURCE_PREFIX } from "@/constants";
import { ImportDashboardModal } from "@/features/importing";
import { ListImageContainer } from "@/features/libraryOverview/routes/LibraryMainPage/style";
import { LibraryItem } from "@/types/libraryItem";

interface Props {
    libraryItem: LibraryItem;
}

export const LibraryItemCard: React.FC<Props> = ({ libraryItem }) => {
    const [showImportLibraryItemModal, setShowImportLibraryItemModal] = useState(false);

    return (
        <>
            <Card
                sx={{
                    overflow: "hidden",
                    cursor: "pointer",
                    padding: 0,
                    maxHeight: "15vw",
                    minHeight: "15vw",
                    boxShadow: 2,
                }}
                onClick={() => setShowImportLibraryItemModal(true)}
            >
                <CardContent>
                    <Typography variant="textSm">{libraryItem.title}</Typography>
                    <ListImageContainer
                        src={IMAGE_SOURCE_PREFIX + libraryItem.coverimage}
                        alt="coverimage"
                        crossOrigin="anonymous"
                    />
                </CardContent>
            </Card>
            {showImportLibraryItemModal && (
                <ImportDashboardModal
                    open={showImportLibraryItemModal}
                    onClose={() => setShowImportLibraryItemModal(false)}
                    libraryItem={libraryItem}
                />
            )}
        </>
    );
};
