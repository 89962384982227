import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { LibraryMainModule } from "@/features/libraryOverview";
import { getLibraryUrl } from "@/features/libraryOverview/helpers";
import { PublishedLibraryItemsPage } from "@/features/published";
import { PublishDashboardPage } from "@/features/publishing";
import { DashboardLibraryItemType } from "@/types/libraryItem";

export const AppRoutes: React.FC = () => {
    return (
        <Switch>
            <Route path={["library/published/:itemType/"]} component={PublishedLibraryItemsPage} />
            <Route path={["/library/dashboard/publish/:dashboardId/"]} component={PublishDashboardPage} />
            <Route path={["/library/:tab"]} component={LibraryMainModule} />
            <Route
                path={["/library/"]}
                render={() => <Redirect to={getLibraryUrl("/library/", DashboardLibraryItemType)} />}
            />
        </Switch>
    );
};
