import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { useAllLibraryCollections } from "@/entities/libraryCollections";
import { CollectionPreview } from "@/features/libraryOverview/components/CollectionPreview";
import { LibraryItemCard } from "@/features/libraryOverview/components/LibraryItemCard";
import { getFormattedCollectionName } from "@/features/libraryOverview/helpers";
import { fm } from "@/providers/IntlContextProvider";
import { LibraryCollection, LibraryItem, LibraryItemType } from "@/types/libraryItem";

import messages from "./messages";

interface Props {
    collection?: LibraryCollection;
    onCollectionSelected: (updatedCollection?: LibraryCollection) => void;
    libraryItems: LibraryItem[];
    isLoading: boolean;
    isSearch: boolean;
}

export const LibraryListContainer: React.FC<Props> = ({
    collection,
    onCollectionSelected,
    libraryItems,
    isLoading,
    isSearch,
}) => {
    const { tab } = useParams<{ tab: LibraryItemType }>();
    const allCollectionsForLibraryItemType = useAllLibraryCollections(
        { libraryItemType: tab },
        { service: "dashboards" }
    ).filter((coll) => coll.count > 0);

    const relevantItems =
        collection === undefined
            ? libraryItems
            : libraryItems.filter((item) => item.collections.includes(collection.id));

    // TODO: Fix loading
    if (isLoading || libraryItems.length === 0) {
        return (
            <Stack sx={{ width: "100%", height: "100%" }} direction="row" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Stack>
        );
    }

    if (isSearch) {
        return (
            <Stack>
                <Stack direction="row" p={2}>
                    <Typography variant="textMd" fontWeight={600}>
                        {fm(messages.searchResults)}
                    </Typography>
                </Stack>

                <Grid container spacing={2}>
                    {relevantItems.map((item: LibraryItem, index: number) => (
                        <Grid item xs={4} key={item.id} padding={index > 2 ? [2, 0, 0, 2] : [0.5, 0, 0, 2]}>
                            <LibraryItemCard libraryItem={item} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        );
    }

    if (collection === undefined) {
        return (
            <Stack>
                {allCollectionsForLibraryItemType.map((singleCollection) => (
                    <CollectionPreview
                        key={singleCollection.id}
                        collection={singleCollection}
                        libraryItems={libraryItems}
                        onCollectionSelected={onCollectionSelected}
                    />
                ))}
            </Stack>
        );
    }

    return (
        <Stack>
            <Stack direction="row" pt={2} pl={2}>
                <Typography variant="textMd" fontWeight={600}>
                    {getFormattedCollectionName(collection.name)}
                </Typography>
            </Stack>

            <Grid container>
                {relevantItems.map((item: LibraryItem, index: number) => (
                    <Grid item xs={4} key={item.id} padding={index > 2 ? [2, 0, 0, 2] : [0.5, 0, 0, 2]}>
                        <LibraryItemCard libraryItem={item} />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};
