import { Container, Stack, Typography } from "@mui/material";
import React from "react";

import { UploadField } from "@/components/UploadFile";
import { fm } from "@/providers/IntlContextProvider";

import { File } from "./File";
import messages from "./messages";

interface Props {
    onFileSelected: (file: File | undefined) => void;
    file?: File;
}

export const ImageUpload: React.FC<Props> = ({ onFileSelected, file }) => {
    return (
        <Container>
            <Stack direction="row">
                <Typography variant="h6">{fm(messages.selectCoverImage)}</Typography>
            </Stack>
            <Stack direction="row">
                {file !== undefined ? (
                    <File
                        name={file.name}
                        url={URL.createObjectURL(file)}
                        onDeleteFile={() => onFileSelected(undefined)}
                    />
                ) : (
                    <UploadField
                        showDropField
                        title={fm(messages.uploadFile)}
                        name="file"
                        dropAreaLabel={fm(messages.dropAreaLabel).toString()}
                        id="coverImage-file"
                        onChange={onFileSelected}
                    />
                )}
            </Stack>
        </Container>
    );
};
