import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";

import { LibraryCollection } from "@/types/libraryItem";

const listCreateResource = createListResource<LibraryCollection>`/global-types/collections/${(params) =>
    params.libraryItemType || ""}/`;
const detailResource = createDetailResource<any>`/global-types/collections/NOT-A-DETAIL-VIEW/${(params) => params.id}/`;

export const { Provider: LibraryCollectionProvider, useAll: useAllLibraryCollections } = createEntityContext(
    listCreateResource,
    detailResource,
    {
        interchangableTypes: false,
        name: "Library collection",
    }
);
