import { defineMessages } from "react-intl";

const messages = defineMessages({
    selectCoverImage: {
        id: "components.imageUpload.selectCoverImage",
        defaultMessage: "Select cover image",
    },
    uploadFile: {
        id: "components.imageUpload.uploadFile",
        defaultMessage: "Upload file",
    },
    dropAreaLabel: {
        id: "components.imageUpload.dropAreaLabel",
        defaultMessage: "Drop cover photo here",
    },
});

export default messages;
