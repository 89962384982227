import { fm } from "@/providers/IntlContextProvider";
import { LibraryItemType } from "@/types/libraryItem";

import { LIBRARY_COLLECTIONS_MESSAGE_DESCRIPTORS } from "./constants";

export const getLibraryUrl = (pathname: string, libraryItemType: LibraryItemType, itemId?: number) => {
    const prefix = pathname.slice(0, pathname.indexOf("/library/"));
    return `${prefix}/library/${libraryItemType}/${itemId ?? ""}`;
};

export const getFormattedCollectionName = (collectionName: string) => {
    const messageDescriptor = LIBRARY_COLLECTIONS_MESSAGE_DESCRIPTORS[collectionName];
    if (messageDescriptor !== undefined) {
        return fm(messageDescriptor);
    }
    return collectionName;
};
