import { defineMessages } from "react-intl";

const messages = defineMessages({
    collections: {
        id: "features.collectionMenu.containers.libraryCollectionMenu.collections",
        defaultMessage: "Collections",
    },
});

export default messages;
