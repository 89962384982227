import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { RouteTab } from "./interfaces";

interface Props {
    tabs: RouteTab[];
}

export const RouteTabs: React.FC<Props> = ({ tabs }) => {
    const location = useLocation();
    const browserHistory = useHistory();
    const value = tabs.findIndex((tab) => tab.path === location.pathname);

    const handleClick = (tab: RouteTab) => {
        if (tab.path) {
            tab.path && browserHistory.push(tab.path);
        }
    };
    return (
        <Tabs value={value} variant="fullWidth">
            {tabs.map((tab) => (
                <Tab label={tab.name.toString()} onClick={() => handleClick(tab)} key={`${tab.name}`} />
            ))}
        </Tabs>
    );
};
