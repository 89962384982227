import { Autocomplete, Stack, TextField } from "@mui/material";
import React from "react";

import { ImageUpload } from "@/components/ImageUpload";
import { useAllLibraryCollections } from "@/entities/libraryCollections";
import { getFormattedCollectionName } from "@/features/libraryOverview/helpers";
import { fm } from "@/providers/IntlContextProvider";
import { LibraryItem } from "@/types/libraryItem";

import messages from "../EditLibraryItemModal/messages";

interface Props {
    modifiedLibraryItem: LibraryItem;
    coverImage?: File;
    onImageFileSelected: (file?: File) => void;
    onModifiedLibraryItemUpdated: (item: LibraryItem) => void;
}

export const EditableFields: React.FC<Props> = ({
    modifiedLibraryItem,
    coverImage,
    onImageFileSelected,
    onModifiedLibraryItemUpdated,
}) => {
    const { itemType } = modifiedLibraryItem;
    const itemTypeCollections = useAllLibraryCollections({ libraryItemType: itemType }, { service: "dashboards" });

    const getCollectionLabel = (collectionId: number) => {
        const collection = itemTypeCollections.find(({ id }) => id === collectionId);
        return collection
            ? getFormattedCollectionName(collection.name).toString()
            : fm(messages.invalidCollection).toString();
    };

    return (
        <Stack p={1} gap={2}>
            <Stack direction="row">
                <TextField
                    label={fm(messages.title)}
                    variant="outlined"
                    fullWidth
                    value={modifiedLibraryItem.title}
                    onChange={({ target: { value } }) =>
                        onModifiedLibraryItemUpdated({ ...modifiedLibraryItem, title: value })
                    }
                />
            </Stack>
            <Stack direction="row">
                <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => getCollectionLabel(option.id)}
                    options={itemTypeCollections.map(({ id }) => ({ id }))}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label={fm(messages.collections)} />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={modifiedLibraryItem.collections.map((id) => ({ id }))}
                    multiple
                    onChange={(_, values) =>
                        onModifiedLibraryItemUpdated({
                            ...modifiedLibraryItem,
                            collections: values.map(({ id }) => id),
                        })
                    }
                />
            </Stack>
            <Stack direction="row">
                <TextField
                    label={fm(messages.description)}
                    multiline
                    fullWidth
                    rows={7}
                    maxRows={20}
                    value={modifiedLibraryItem.description}
                    onChange={({ target: { value } }) =>
                        onModifiedLibraryItemUpdated({
                            ...modifiedLibraryItem,
                            description: value,
                        })
                    }
                />
            </Stack>
            <Stack direction="row">
                <ImageUpload file={coverImage} onFileSelected={onImageFileSelected} />
            </Stack>
        </Stack>
    );
};
