import { DataColumnV2 } from "@ignite-analytics/data-table";
import { GlobalColumnType, GlobalTableType } from "@ignite-analytics/global-types";
import * as Yup from "yup";

import { fm } from "@/providers/IntlContextProvider";
import { Field, GlobalTypeMapping } from "@/types/globalTypeMapping";

import messages from "./messages";

/**
 * Helper for generating unique IDs across DataTables/DataColums/GroupTypes
 * @param field the object for which to generate a unique ID
 * @returns an ID consisting of the class name of Field and the id of the corresponding object
 */
export const getObjectId = (field: Field) => `${field.className}_${field.id}`;

const DATA_TABLE_V2_TYPE = "DataTableV2";
const DATA_COLUMN_V2_TYPE = "DataColumnV2";

const upperCaseFirstLetter = (string: string) => {
    return `${string[0].toUpperCase()}${string.slice(1)}`;
};

/**
 * Helper for setting the data type column for all types of fields, the data type for columns, "table" for tables, and "group" for groups.
 */
export const getFieldType = (field: Field) => {
    switch (field.className) {
        case DATA_COLUMN_V2_TYPE:
            return upperCaseFirstLetter((field as DataColumnV2).dataType.replace("_", " ").toLowerCase());
        case DATA_TABLE_V2_TYPE:
            return fm(messages.table).toString();
        default:
            return "";
    }
};

/**
 *
 * @param field the field to retrieve options for
 * @param globalTableTypes the base list of available global table types to be filtered and return available options
 * @param globalColumnTypes the base list of available global column types to be filtered and return available options, currently filtered on data type
 * @param globalGroupTypeTypes the base list of available global group type types to be filtered and return available options
 * @returns The available options for the given field.
 */
export const getOptions = (
    field: Field,
    globalTableTypes: GlobalTableType[],
    globalColumnTypes: GlobalColumnType[]
) => {
    switch (field.className) {
        case DATA_COLUMN_V2_TYPE:
            if (field.dataType === "GROUP_STRUCTURE") {
                return globalColumnTypes.filter((globalColumnType) => globalColumnType.dataType === "grouping");
            }
            return globalColumnTypes.filter(
                (globalColumnType) =>
                    globalColumnType.dataType === field.dataType.toLowerCase() ||
                    (field.dataType === "MONETARY_AMOUNT" && globalColumnType.dataType === "number")
            );
        case DATA_TABLE_V2_TYPE:
            return globalTableTypes;

        default:
            return [];
    }
};

/**
 * Validation schema for the global types in DMS V2
 * Need some special logic for checking what global types that are set
 * */
export const globalTypeSchemaV2 = (globalTypeMapping: GlobalTypeMapping) =>
    Yup.object().shape({
        ...Object.fromEntries(
            Object.entries(globalTypeMapping).map(([uniqueId, field]) => [
                uniqueId,
                Yup.object().shape({
                    globalType: field.required
                        ? Yup.mixed()
                              .typeError(fm(messages.missingGlobalTypeError))
                              .required(fm(messages.missingGlobalTypeError))
                              .test("no-duplicates", fm(messages.duplicateGlobalTypeError), async (value) => {
                                  return Promise.resolve(
                                      Object.values(globalTypeMapping).every(
                                          (f) =>
                                              !(f.globalType === value && f.className === field.className) ||
                                              getObjectId(f) === getObjectId(field)
                                      )
                                  );
                              })
                        : Yup.mixed().nullable(),
                }),
            ])
        ),
    });
