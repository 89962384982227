import { defineMessages } from "react-intl";

const messages = defineMessages({
    description: {
        id: "features.publishing.components.publishDashboardSteps.description",
        defaultMessage: "Description",
    },
    widgetName: {
        id: "features.publishing.components.publishDashboardSteps.widgetName",
        defaultMessage: "Widget name",
    },
    dashboardDetails: {
        id: "features.publishing.components.publishDashboardSteps.dashboardDetails",
        defaultMessage: "Dashboard details",
    },
    linkPlaceholder: {
        id: "features.publishing.components.publishDashboardSteps.descriptionPlaceholder",
        defaultMessage: "https://help.igniteprocurement.com...",
    },
    coverPhoto: {
        id: "features.publishing.components.publishDashboardSteps.coverPhoto",
        defaultMessage: "Cover photo",
    },
    title: {
        id: "features.publishing.components.publishDashboardSteps.title",
        defaultMessage: "Title",
    },
    collections: {
        id: "features.publishing.components.publishDashboardSteps.collections",
        defaultMessage: "Collections",
    },
    linkToHelpCenter: {
        id: "features.publishing.components.publishDashboardSteps.linkToHelpCenter",
        defaultMessage: "Help center article",
    },
    letsGo: {
        id: "features.publishing.components.publishDashboardSteps.letsGo",
        defaultMessage: "Let's go",
    },
    publish: {
        id: "features.publishing.components.publishDashboardSteps.publish",
        defaultMessage: "Publish",
    },
    confirmation: {
        id: "features.publishing.components.publishDashboardSteps.confirmation",
        defaultMessage: "Are you sure you wish to add this dashboard to the library?",
    },
    noTitleError: {
        id: "features.publishing.components.publishDashboardSteps.noTitleError",
        defaultMessage: "Title is a required field.",
    },
    tooShortError: {
        id: "features.publishing.components.publishDashboardSteps.tooShortError",
        defaultMessage: "The title must be at least four characters.",
    },
    tooLongError: {
        id: "features.publishing.components.publishDashboardSteps.tooLongError",
        defaultMessage: "The title cannot exceed 50 characters.",
    },
    collectionRequiredError: {
        id: "features.publishing.components.publishDashboardSteps.collectionRequiredError",
        defaultMessage: "You must select at least one collection.",
    },
    widgetDescriptionPlaceholder: {
        id: "features.publishing.components.publishDashboardSteps.widgetDescriptionPlaceholder",
        defaultMessage: "Provides insights into...",
    },
    publishDashboard: {
        id: "features.publishing.components.publishDashboardSteps.publishDashboard",
        defaultMessage: "Publish dashboard",
    },
    noDescriptionError: {
        id: "features.publishing.components.publishDashboardSteps.publishDashboard",
        defaultMessage: "Description is a required field.",
    },
    yourDashboardIsNowPublished: {
        id: "features.publishing.components.publishDashboardSteps.yourDashboardIsNowPublished",
        defaultMessage: "Your dashboard is now published",
    },
    checkItOut: {
        id: "features.publishing.components.publishDashboardSteps.checkItOut",
        defaultMessage: "Do you want to check it out in the library?",
    },
    noCoverimageError: {
        id: "features.publishing.components.publishDashboardSteps.noCoverimageError",
        defaultMessage: "Cover image is a required field.",
    },
    couldNotUploadTheImage: {
        id: "features.publishing.components.publishDashboardSteps.couldNotUploadTheImage",
        defaultMessage: "Could not upload the image, but the item is published.",
    },
    fileType: {
        id: "features.publishing.components.publishDashboardSteps.fileType",
        defaultMessage: "The file type must be either .png, .jpeg, or .jpg",
    },
    noDescription: {
        id: "features.publishing.components.publishDashboardSteps.noDescription",
        defaultMessage: "No description",
    },
    cancelButton: {
        id: "features.publishing.components.publishDashboardSteps.cancelButton",
        defaultMessage: "Cancel",
    },
    goBackButton: {
        id: "features.publishing.components.publishDashboardSteps.goBackButton",
        defaultMessage: "Back",
    },
    nextButton: {
        id: "features.publishing.components.publishDashboardSteps.nextButton",
        defaultMessage: "Next",
    },
    failedToPublishDashboard: {
        id: "features.publishing.components.publishDashboardSteps.failedToPublishDashboard",
        defaultMessage: "Failed to publish dashboard",
    },
});

export default messages;
