import { Api } from "@ignite-analytics/api-client";
import { GlobalColumnType, GlobalGroupTypeType, GlobalTableType } from "@ignite-analytics/global-types";

import { CustomDashboard } from "@/types/dashboard";
import { CreateWidgetFromLibraryItemRequestBody } from "@/types/libraryItem";

import { ImportData } from "../types";

export interface PreservedGlobalTypes {
    preservedGlobalTableType: GlobalTableType;
    preservedGlobalColumnTypes: GlobalColumnType[];
    preservedGlobalGroupTypeTypes: GlobalGroupTypeType[];
}
interface GlobalTypeConfigResponse {
    data: PreservedGlobalTypes[];
    configurationNeeded: boolean;
}

/**
 * Sends a post request to create a new dashboard from the library template.
 */
export const importDashboardFromLibrary = (data: ImportData): Promise<CustomDashboard> => {
    const url = `/library/items/${data.itemType}/${data.id}/import/`;
    return Api.post(url, data, { service: "dashboards" });
};

/**
 * Sends a post request to create a new widget from the library template.
 */
export const importWidgetFromLibraryItem = (libraryItemId: number, data: CreateWidgetFromLibraryItemRequestBody) => {
    const url = `/library/items/widget/${libraryItemId}/import/`;
    return Api.post(url, data, { service: "dashboards" });
};

/**
 * Send a get request to get global type configuration fields from imported dashboard
 */
export const getGlobalTypeConfigTemplateFromLibrary = (data: ImportData): Promise<GlobalTypeConfigResponse> => {
    const url = `/library/items/${data.itemType}/${data.id}/global-type-config/`;
    return Api.post(url, data, { service: "dashboards" });
};
