import { Typography } from "@mui/material";
import React, { DragEventHandler, useState } from "react";

import { StyledDropArea } from "./style";

interface Props {
    onChange: (files: File) => void;
    text?: string | JSX.Element;
}

export const DropArea: React.FC<Props> = ({ onChange, text = "Drop files here" }) => {
    const [dropHover, setHover] = useState(false);
    const [counter, setCounter] = useState(0);

    const removeDragData: DragEventHandler<HTMLDivElement> = (e) => {
        if (e.dataTransfer === null) return;

        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to remove the drag data
            e.dataTransfer.items.clear();
        } else {
            // Use DataTransfer interface to remove the drag data
            e.dataTransfer.clearData();
        }
    };

    const dragOverHandler: DragEventHandler<HTMLDivElement> = (e) => {
        // Prevent default behavior (Prevent file from being opened)
        e.preventDefault();
    };

    const dragEnterHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();

        setCounter(counter + 1);
        setHover(true);
    };

    const dragLeaveHandler = () => {
        setCounter(counter - 1);
        setHover(false);
    };

    const onFiles = (files: FileList | (File | null)[] | null) => {
        let outputFiles = files || [];
        outputFiles = Array.from(outputFiles);
        onChange(outputFiles.filter((f) => !!f)[0] as File);
    };

    const dropHandler: DragEventHandler<HTMLDivElement> = (e) => {
        // Prevent default behavior (Prevent file from being opened)
        e.preventDefault();

        const files = e.dataTransfer
            ? e.dataTransfer.items
                ? Array.from(e.dataTransfer.items)
                      .filter((item: DataTransferItem) => item.kind === "file")
                      .map((item) => item.getAsFile())
                : e.dataTransfer.files
            : null;

        if (files) onFiles(files);

        // Pass event to removeDragData for cleanup
        removeDragData(e);
    };

    return (
        <StyledDropArea
            hover={dropHover}
            onDrop={dropHandler}
            onDragOver={dragOverHandler}
            onDragEnter={dragEnterHandler}
            onDragLeave={dragLeaveHandler}
            sx={{ padding: 3, textAlign: "center" }}
        >
            <Typography variant="h6">{text}</Typography>
        </StyledDropArea>
    );
};
