import { GraphqlRequestContainer } from "@ignite-analytics/graphql-utilities";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { PreservedGlobalTypes } from "@/features/importing/api/importing";
import messages from "@/features/importing/components/ConfigureGlobalTypesDialogForDashboard/messages";
import { SelectGlobalColumnTypesTable } from "@/features/importing/components/SelectGlobalColumnTypesTable";
import { DataTable, useGetDataColumnsQuery } from "@/generated/client";
import { fm } from "@/providers/IntlContextProvider";
import { GlobalTypeMapping } from "@/types/globalTypeMapping";

import { getGlobalTypesToConfigure } from "./helpers";

interface Props {
    preservedGlobalTypes: PreservedGlobalTypes;
    setGlobalTypeMapping: React.Dispatch<React.SetStateAction<GlobalTypeMapping>>;
    globalTypeMapping: GlobalTypeMapping;
    dataTables: DataTable[];
    chosenDataTable?: DataTable;
}
const getErrorMessageFromDataTable = (selectedDataTable?: DataTable) =>
    selectedDataTable ? undefined : fm(messages.missingGlobalTableTypeError);

export const ConfigureGlobalTypesTable: React.FC<Props> = ({
    preservedGlobalTypes,
    setGlobalTypeMapping,
    globalTypeMapping,
    dataTables,
    chosenDataTable,
}) => {
    const dataTableFromGlobalType = preservedGlobalTypes
        ? dataTables.find((dt) => dt.globalTypeKey === preservedGlobalTypes.preservedGlobalTableType.key)
        : undefined;
    const initialDataTable = chosenDataTable ?? dataTableFromGlobalType;
    const [selectedDataTable, setSelectedDataTable] = useState(initialDataTable);

    useEffect(() => {
        if (initialDataTable && selectedDataTable === undefined) {
            setSelectedDataTable(initialDataTable);
        }
    }, [initialDataTable, selectedDataTable]);

    const selectableDataTables: DataTable[] = Object.values(dataTables)
        .flat()
        .filter((dt) => !dt.globalTypeKey);

    const { result: dataColumnsResult } = useGetDataColumnsQuery(
        {
            input: { dataTableId: selectedDataTable?.id || "", columnSelection: "ONLY_INDEXED" },
        },

        { skip: !selectedDataTable }
    );

    const globalTypesToConfigure = useMemo(
        () =>
            dataColumnsResult.type === "success"
                ? getGlobalTypesToConfigure(dataColumnsResult.data.dataColumns, preservedGlobalTypes)
                : [],
        [dataColumnsResult, preservedGlobalTypes]
    );

    const handleGlobalTableTypeValueChange = (dataTableId: string) => {
        const dataTable = dataTables.find((dt) => dt.id.toString() === dataTableId);
        setSelectedDataTable(dataTable);
        if (dataTable) {
            setGlobalTypeMapping((prevState) => ({
                ...prevState,
                [`GlobalTableType_${preservedGlobalTypes.preservedGlobalTableType.id}`]: {
                    ...dataTable,
                    globalType: preservedGlobalTypes.preservedGlobalTableType.id,
                    globalTypeKey: preservedGlobalTypes.preservedGlobalTableType.key,
                    updated: true,
                },
            }));
        } else {
            setGlobalTypeMapping((prevState) => {
                const copy = { ...prevState };
                delete copy[`GlobalTableType_${preservedGlobalTypes.preservedGlobalTableType.id}`];
                return copy;
            });
        }
    };

    const errorMessage = getErrorMessageFromDataTable(selectedDataTable);

    const selectDataTableIfNotSelected = !initialDataTable ? (
        <Stack width="40%">
            <FormControl>
                <InputLabel id={`table-select-label-${preservedGlobalTypes.preservedGlobalTableType.id}`}>
                    {fm(messages.selectYourXTable, {
                        globalTypeName: preservedGlobalTypes.preservedGlobalTableType.name,
                    }).toString()}
                </InputLabel>
                <Select
                    labelId={`table-select-label-${preservedGlobalTypes.preservedGlobalTableType.id}`}
                    label={fm(messages.selectYourXTable, {
                        globalTypeName: preservedGlobalTypes.preservedGlobalTableType.name,
                    }).toString()}
                    name={`${preservedGlobalTypes.preservedGlobalTableType.id}.globalTableType`}
                    value={selectedDataTable?.id.toString()}
                    onChange={(e) => handleGlobalTableTypeValueChange(e.target.value)}
                    error={!!errorMessage}
                    required
                >
                    {selectableDataTables.map((dataTableSource) => (
                        <MenuItem key={dataTableSource.id} value={dataTableSource.id.toString()}>
                            {dataTableSource.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    ) : null;

    return (
        <GraphqlRequestContainer
            asyncData={dataColumnsResult}
            loading={
                <>
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={30} />
                    <Skeleton width="100%" height={30} />
                </>
            }
        >
            {(data) => (
                <Container>
                    <Stack direction="row">
                        <Stack>
                            <Stack direction="row">{selectDataTableIfNotSelected}</Stack>
                            <Stack direction="row" pt={2} gap={1}>
                                <Typography variant="body1">
                                    {fm(messages.dataTableSourceHeading, {
                                        tableName: preservedGlobalTypes.preservedGlobalTableType.name.toLowerCase(),
                                    })}
                                    <Typography variant="body1" color="primary" paddingLeft={0.5} display="inline">
                                        {selectedDataTable ? selectedDataTable.name : fm(messages.noDataTableSelected)}
                                    </Typography>
                                </Typography>
                                {initialDataTable && (
                                    <Tooltip title={fm(messages.alreadyConfiguredGlobalTypeConfig)}>
                                        <HelpOutlineIcon sx={{ cursor: "pointer" }} fontSize="small" />
                                    </Tooltip>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction="row" pt={2}>
                        {selectedDataTable && globalTypesToConfigure.length ? (
                            <SelectGlobalColumnTypesTable
                                globalTypesToConfigure={globalTypesToConfigure}
                                dataColumns={data.dataColumns}
                                globalTypeMapping={globalTypeMapping}
                                setGlobalTypeMapping={setGlobalTypeMapping}
                            />
                        ) : null}
                    </Stack>
                    <Stack direction="row-reverse" pr={2}>
                        <Tooltip title={fm(messages.unableToFindFieldTooltip)}>
                            <Typography color="gray" sx={{ fontSize: "12px", cursor: "pointer" }}>
                                {fm(messages.unableToFindField)}
                            </Typography>
                        </Tooltip>
                    </Stack>
                </Container>
            )}
        </GraphqlRequestContainer>
    );
};
