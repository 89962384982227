import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

import { BackgroundImageContainer, LinearGradient } from "@/features/libraryOverview/routes/LibraryMainPage/style";
import { fm } from "@/providers/IntlContextProvider";
import { LibraryItem } from "@/types/libraryItem";

import messages from "./messages";

interface Props {
    libraryItem: LibraryItem;
    imageUrl: string;
    onEditButtonClicked?: () => void;
}

export const LibraryDetailHeader: React.FC<Props> = ({ libraryItem, imageUrl, onEditButtonClicked }) => {
    return (
        <Stack direction="row" sx={{ position: "relative" }}>
            <BackgroundImageContainer src={imageUrl} alt="coverphoto" crossOrigin="anonymous" />
            <LinearGradient />
            <Stack direction="row" sx={{ position: "absolute", top: 1, right: 1 }}>
                {onEditButtonClicked !== undefined && (
                    <Tooltip title={fm(messages.edit)}>
                        <IconButton size="small" type="button" onClick={() => onEditButtonClicked()}>
                            <EditIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>
            <Stack direction="row" sx={{ position: "absolute", bottom: 0 }}>
                <Stack margin={[0, 0, 0, 2]} sx={{ textOverflow: "ellipsis", overflowX: "hidden" }}>
                    <Typography fontWeight={500} color="white" variant="h4">
                        {libraryItem.title}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};
