import { MessageDescriptor } from "react-intl";

import messages from "./routes/LibraryMainPage/messages";

export const LIBRARY_COLLECTIONS_MESSAGE_DESCRIPTORS: Record<string, MessageDescriptor> = {
    spendOverview: messages.spendOverview,
    spendCategories: messages.spendCategories,
    spendDevelopment: messages.spendDevelopment,
    spendDistribution: messages.spendDistribution,
    spendItems: messages.spendItems,
    spendInvoices: messages.spendInvoices,
    suppliers: messages.suppliers,
    dataDetailsSpend: messages.dataDetailsSpend,
    dataDetailsContracts: messages.dataDetailsContracts,
    contracts: messages.contracts,
    tasks: messages.tasks,
    notifications: messages.notifications,
    savingsTracker: messages.savingsTracker,
    opportunities: messages.opportunities,
    risks: messages.risks,
    benchmarking: messages.benchmarking,
};
