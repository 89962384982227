import { Stack, Typography } from "@mui/material";
import React from "react";

import { IMAGE_SOURCE_PREFIX } from "@/constants";
import { LibraryDetailHeader } from "@/features/published/components/LibraryDetailHeader";
import { fm } from "@/providers/IntlContextProvider";
import { LibraryItem } from "@/types/libraryItem";

import messages from "../EditLibraryItemModal/messages";

interface Props {
    libraryItem: LibraryItem;
    previewImageUrl?: string;
}

export const EditLibraryItemDetailView: React.FC<Props> = ({ libraryItem, previewImageUrl }) => {
    const imageUrl = previewImageUrl ?? IMAGE_SOURCE_PREFIX + libraryItem.coverimage;

    return libraryItem === undefined ? null : (
        <Stack p={1}>
            <LibraryDetailHeader libraryItem={libraryItem} imageUrl={imageUrl ?? ""} />
            <Stack pt={2} direction="row">
                <Typography variant="h6">{fm(messages.description)}</Typography>
            </Stack>
            <Stack direction="row">
                {libraryItem.description.split("\n").map((paragraph) => (
                    <Stack direction="row" key={paragraph}>
                        <Typography variant="body1">{paragraph}</Typography>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};
