import { useAsyncData } from "@ignite-analytics/api-client";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormHelperText,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";

import { useAllGlobalColumnTypes } from "@/entities/globalColumnType";
import { useAllGlobalTableTypes } from "@/entities/globalTableType";
import { getGlobalTypesAlreadyInUse } from "@/features/publishing/api/globalTypes";
import { fm, getGlobalTypeName } from "@/providers/IntlContextProvider";
import { Field, GlobalTypeMapping } from "@/types/globalTypeMapping";

import { getFieldType, getObjectId, getOptions, globalTypeSchemaV2 } from "../helpers";
import messages from "../messages";

interface Props {
    /* The current global types */
    globalTypeMapping: GlobalTypeMapping;
    /* Set the global type mnapping */
    setGlobalTypeMapping: React.Dispatch<React.SetStateAction<GlobalTypeMapping | undefined>>;
    /* Fields that should be grouped together in the table, e.g. a widget with one data table and multiple columns and group types  */
    fieldGroups: { groupTitle: string; fields: Field[] }[];
    /* Handler for canceling the form */
    onBack: () => void;
    /* Handler for submission */
    onNext: () => void;
    /* Loading state */
    loading?: boolean;
}

export const ConfigureGlobalTypeTable: React.FC<Props> = ({
    globalTypeMapping,
    setGlobalTypeMapping,
    fieldGroups,
    onBack,
    onNext,
    loading,
}) => {
    // Fetch the global types already in use
    const { requestState } = useAsyncData(getGlobalTypesAlreadyInUse);

    // Fetch all the available global types for columns, groups, and tables to provide the options.
    const allGlobalColumnTypes = useAllGlobalColumnTypes(undefined, {
        service: "dashboards",
    });
    const allGlobalTableTypes = useAllGlobalTableTypes(undefined, {
        service: "dashboards",
    });

    const containsId = (mapping: Record<number, number>, id: number): boolean => Object.values(mapping).includes(id);

    const availableGlobalColumnTypes = React.useMemo(() => {
        if (requestState.type !== "success") return;
        return allGlobalColumnTypes.filter(({ id }) => !containsId(requestState.data.globalColumnTypes, id));
    }, [allGlobalColumnTypes, requestState]);

    const availableGlobalTableTypes = React.useMemo(() => {
        if (requestState.type !== "success") return;
        return allGlobalTableTypes.filter(({ id }) => !containsId(requestState.data.globalTableTypes, id));
    }, [allGlobalTableTypes, requestState]);

    const validationSchema = React.useMemo(() => globalTypeSchemaV2(globalTypeMapping), [globalTypeMapping]);

    return (
        <Box>
            <Formik
                onSubmit={() => onNext()}
                validationSchema={validationSchema}
                initialValues={globalTypeMapping}
                validateOnMount={true}
                enableReinitialize
            >
                {(formikProps) => (
                    <Form
                        style={{
                            width: "100%",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Stack>
                            {requestState.type === "success" &&
                            availableGlobalColumnTypes &&
                            availableGlobalTableTypes ? (
                                <Stack direction="row">
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <Typography fontWeight={700}>{fm(messages.name)}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography fontWeight={700}>{fm(messages.fieldName)}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography fontWeight={700}>{fm(messages.fieldType)}</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography fontWeight={700}>{fm(messages.globalType)}</Typography>
                                        </Grid>
                                        {fieldGroups.map((fieldGroup) =>
                                            fieldGroup.fields
                                                .filter((field) => !field.globalType)
                                                .map((field) => (
                                                    <React.Fragment key={`${fieldGroup.groupTitle}-${field.id}`}>
                                                        <Grid item xs={3}>
                                                            <Typography>{fieldGroup.groupTitle}</Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography>{field.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography>{getFieldType(field)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Autocomplete
                                                                disablePortal
                                                                options={getOptions(
                                                                    field,
                                                                    availableGlobalTableTypes,
                                                                    availableGlobalColumnTypes
                                                                ).map((globalType) => ({
                                                                    id: globalType.id.toString(),
                                                                    label: getGlobalTypeName(globalType),
                                                                }))}
                                                                size="small"
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        required
                                                                        name={getObjectId(field)}
                                                                        label={fm(messages.globalType).toString()}
                                                                        {...params}
                                                                    />
                                                                )}
                                                                disableClearable
                                                                isOptionEqualToValue={(option, value) =>
                                                                    option.id === value.id
                                                                }
                                                                onChange={(_, value) => {
                                                                    const selectedGlobalTypeId = value
                                                                        ? value.id
                                                                        : undefined;
                                                                    if (!selectedGlobalTypeId) return;
                                                                    const globalType = getOptions(
                                                                        field,
                                                                        availableGlobalTableTypes,
                                                                        availableGlobalColumnTypes
                                                                    ).find(
                                                                        ({ id }) =>
                                                                            selectedGlobalTypeId === id.toString()
                                                                    );
                                                                    if (!globalType) return;
                                                                    setGlobalTypeMapping(
                                                                        (prevState) =>
                                                                            prevState && {
                                                                                ...prevState,
                                                                                [getObjectId(field)]: {
                                                                                    ...prevState[getObjectId(field)],
                                                                                    globalType: globalType.id,
                                                                                    globalTypeKey: globalType.key,
                                                                                    updated: true,
                                                                                },
                                                                            }
                                                                    );
                                                                }}
                                                            />
                                                            <FormHelperText error>
                                                                {
                                                                    formikProps.errors[`${getObjectId(field)}`]
                                                                        ?.globalType
                                                                }
                                                            </FormHelperText>
                                                        </Grid>
                                                    </React.Fragment>
                                                ))
                                        )}
                                    </Grid>
                                </Stack>
                            ) : (
                                <CircularProgress />
                            )}
                            <Stack direction="row" gap={1} pt={2} justifyContent="flex-end">
                                <Stack>
                                    <Button color="secondary" disabled={loading} onClick={onBack}>
                                        {fm(messages.goBackButton)}
                                    </Button>
                                </Stack>
                                <Stack>
                                    <Button type="submit" color="primary" disabled={loading || !formikProps.isValid}>
                                        {fm(messages.nextButton)}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
