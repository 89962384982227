import * as Yup from "yup";

import { fm } from "@/providers/IntlContextProvider";

import messages from "./messages";

export const widgetSchema = () =>
    Yup.object({
        widgets: Yup.array(
            Yup.object({
                description: Yup.string().notRequired().nullable(),
            })
        ),
    });

export const backgroundSchema = () =>
    Yup.object({
        title: Yup.string()
            .required(fm(messages.noTitleError))
            .min(4, fm(messages.tooShortError))
            .max(100, fm(messages.tooLongError)),
        description: Yup.string().required(fm(messages.noDescriptionError)),
        link: Yup.string().url().notRequired(),
        collections: Yup.array().required().min(1, fm(messages.collectionRequiredError)),
    });
