import { styled } from "@mui/material";

const wordColor = "#244a8a";
const powerPointColor = "#c73d23";
const excelColor = "#19653b";
const pdfColor = "#cf2219";
const imgColor = "#7b4e42";
const defaultColor = "#666361";

const fileColors: { [key: string]: string } = {
    docx: wordColor,
    doc: wordColor,
    pptx: powerPointColor,
    ppt: powerPointColor,
    xlsx: excelColor,
    xls: excelColor,
    pdf: pdfColor,
    png: imgColor,
    jpg: imgColor,
    jpeg: imgColor,
};

const size = 40;

interface FileTypeProps {
    type: string;
}

export const FileType = styled("div")`
    width: ${size}px;
    height: ${size}px;
    line-height: ${size}px;
    text-align: center;
    border-radius: 3px;
    background: ${(props: FileTypeProps) => (props.type in fileColors ? fileColors[props.type] : defaultColor)};

    &:after {
        content: "${(props: FileTypeProps) => props.type}";
        color: white;

        font-weight: 600;
        text-transform: uppercase;
    }
`;
