import { Api } from "@ignite-analytics/api-client";

import { ElasticIndexConfig } from "@/types/globalTypeMapping";

type GlobalTypesAlreadyInUseType = Promise<{
    globalColumnTypes: Record<number, number>;
    globalTableTypes: Record<number, number>;
    globalGroupTypeTypes: Record<number, number>;
}>;

export const getGlobalTypeConfig = (dashboardId: number | string): Promise<Record<number, ElasticIndexConfig>> => {
    const url = `/dashboards/${dashboardId}/global-type-config/`;
    return Api.get(url, {
        cancelKey: `global-type-config-${dashboardId}`,
        service: "dashboards",
    });
};

export const setDashboardGlobalTypes = (dashboardId: number | string) => {
    const url = `/dashboards/${dashboardId}/set-global-types/`;
    return Api.put<void>(url, {}, { service: "dashboards" });
};

export const getGlobalTypesAlreadyInUse = (): GlobalTypesAlreadyInUseType => {
    const url = `global-types/fields/`;
    return Api.get(url, { service: "dashboards" });
};
