import { GlobalColumnType, GlobalGroupTypeType } from "@ignite-analytics/global-types";

import { DataColumn } from "@/generated/client";
import { GlobalTypeMapping } from "@/types/globalTypeMapping";

export const getMappingKey = (globalType: GlobalColumnType | GlobalGroupTypeType) => {
    if ("dataType" in globalType) return `ColumnType_${globalType.id}`;
    return `GroupTypeType_${globalType.id}`;
};
const RELATIONAL_DATA_TYPES = ["contract", "supplier", "relational"];
export const getSelectableFields = (
    globalType: GlobalGroupTypeType | GlobalColumnType,
    dataColumns: DataColumn[],
    globalTypeMapping: GlobalTypeMapping
): DataColumn[] => {
    if ("dataType" in globalType) {
        const alreadyUsedColumns = Object.values(globalTypeMapping)
            .filter((gt) => "dataTableId" in gt && gt.globalTypeKey !== globalType.key && gt.id !== undefined)
            .map(({ id }) => id);
        return dataColumns
            .filter((dc) => {
                if (
                    (dc.dataType === "GROUP_STRUCTURE" && globalType.dataType === "grouping") ||
                    (dc.dataType === "MONETARY_AMOUNT" && globalType.dataType === "number") ||
                    (dc.dataType === "TABLE_RELATION" && RELATIONAL_DATA_TYPES.includes(globalType.dataType))
                )
                    return true;
                return dc.dataType.toLowerCase() === globalType.dataType;
            })
            .filter((dc) => !dc.globalTypeKey && !alreadyUsedColumns.includes(dc.id));
    }
    return [];
};
