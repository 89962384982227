import { defineMessages } from "react-intl";

const messages = defineMessages({
    noLibraryItemsPublished: {
        id: "features.published.routes.publishedLibraryItemsPage.noLibraryItemsPublished",
        defaultMessage: "No published library items.",
    },
    publishedLibraryItems: {
        id: "features.published.routes.publishedLibraryItemsPage.publishedLibraryItems",
        defaultMessage: "Published {itemType}s",
    },
});

export default messages;
