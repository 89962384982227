import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { track } from "@ignite-analytics/track";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Container, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { RouteTabs } from "@/features/libraryOverview/components/RouteTabs";
import { RouteTab } from "@/features/libraryOverview/components/RouteTabs/interfaces";
import { fm } from "@/providers/IntlContextProvider";
import { AccentureDashboardLibraryItemType, DashboardLibraryItemType } from "@/types/libraryItem";

import { getLibraryUrl } from "../../helpers";
import { SearchBar } from "../SearchBar";

import messages from "./messages";

interface Props {
    onSearch: (searchTerm: string) => void;
}

export const LibraryHeader: React.FC<Props> = ({ onSearch }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLButtonElement>(null);
    const menuOpen = Boolean(menuAnchorEl);

    const path = useLocation().pathname;
    const browserHistory = useHistory();
    const prefix = path.slice(0, path.indexOf("/library/"));
    const isInternalUser = useFeatureToggle("library-publish-widget");
    const isAccentureUser = useFeatureToggle("library-accenture-dashboards");
    const accentureDashboardTab: RouteTab[] = isAccentureUser
        ? [
              {
                  name: fm(messages.accentureDashboards),
                  path: getLibraryUrl(path, AccentureDashboardLibraryItemType),
              },
          ]
        : [];

    const tabs: RouteTab[] = [
        {
            name: fm(messages.dashboards),
            path: getLibraryUrl(path, DashboardLibraryItemType),
        },
        ...accentureDashboardTab,
    ];

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event?: React.SyntheticEvent) => {
        event?.stopPropagation();
        setMenuAnchorEl(null);
    };

    return (
        <Container>
            <Stack direction="row" py={2} alignItems="center">
                <Typography variant="h6" fontWeight={700}>
                    {fm(messages.header)}
                </Typography>
                <Stack width="100%" pl={2}>
                    {tabs.length > 1 && <RouteTabs tabs={tabs} />}
                </Stack>
                <Stack width="100%">
                    <Stack direction="row" justifyContent="flex-end">
                        <Stack>
                            <SearchBar onSearch={onSearch} />
                        </Stack>
                        {(isInternalUser || isAccentureUser) && (
                            <>
                                <IconButton size="small" onClick={handleMenuClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={(event) => handleMenuClose(event as React.SyntheticEvent)}
                                >
                                    {isInternalUser && (
                                        <MenuItem
                                            onClick={() => {
                                                browserHistory.push(`${prefix}/library/published/dashboard/`);
                                                track("navigateToLibraryDashboardTab");
                                            }}
                                        >
                                            {fm(messages.myPublishedDashboards)}
                                        </MenuItem>
                                    )}
                                    {isAccentureUser && (
                                        <MenuItem
                                            onClick={() =>
                                                browserHistory.push(`${prefix}/library/published/accenture_dashboard/`)
                                            }
                                        >
                                            {fm(messages.myPublishedAccentureDashboards)}
                                        </MenuItem>
                                    )}
                                </Menu>
                            </>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    );
};
