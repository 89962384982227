import { defineMessages } from "react-intl";

const messages = defineMessages({
    defaultBreadcrumb: {
        id: "hooks.useSetBreadcrumbs.defaultBreadcrumb.dashboardLibrary",
        defaultMessage: "Dashboard library",
    },
});

export default messages;
