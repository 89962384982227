import { useContext } from "react";

import { InfoMessageContext } from "@/providers/InfoMessageProvider";
import { InfoMessage } from "@/types/infoMessages";

type InformUser = (message: InfoMessage) => void;

export function useInformUser(): InformUser {
    const messageContext = useContext(InfoMessageContext);

    if (messageContext === undefined) {
        throw new Error("Missing message context");
    }

    return messageContext.informUser;
}
