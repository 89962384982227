import { getCombinedProvider } from "@ignite-analytics/entities";

import { DashboardCollectionContextProvider } from "./dashboardCollections";
import { CustomDashboardContextProvider } from "./dashboards";
import { GlobalColumnTypeContextProvider } from "./globalColumnType";
import { GlobalTableTypeContextProvider } from "./globalTableType";
import { LibraryCollectionProvider } from "./libraryCollections";
import { LibraryItemProvider } from "./libraryItems";

const _EntitiesContextProvider = getCombinedProvider(
    LibraryCollectionProvider,
    LibraryItemProvider,
    GlobalColumnTypeContextProvider,
    GlobalTableTypeContextProvider,
    DashboardCollectionContextProvider,
    CustomDashboardContextProvider
);

export const EntitiesContextProvider: React.FC<{ children: React.ReactNode }> = _EntitiesContextProvider;
