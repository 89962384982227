import { useEffect } from "react";
import { useIntl } from "react-intl";

import { useBasePath } from "@/providers/BasePathContextProvider";

import messages from "./messages";

export function useSetBreadcrumbs(title?: string) {
    const { formatMessage } = useIntl();
    const path = useBasePath();

    useEffect(() => {
        const payload: { text: string; href?: string }[] = [
            { text: "Analytics", href: "/dashboard/overview/home/" },
            { text: formatMessage(messages.defaultBreadcrumb).toString() },
        ];
        if (title) {
            payload.push({ text: title });
        }

        const data = {
            source: "ignite-breadcrumbs",
            payload,
        };
        window.postMessage(data, window.location.origin);
    }, [title, path, formatMessage]);
}
