import { Container, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useLibraryItemsResults } from "@/entities/libraryItems";
import { useInformUser } from "@/hooks/useInformUser";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { fm } from "@/providers/IntlContextProvider";
import { LibraryItemType } from "@/types/libraryItem";

import { PublishedLibraryItemCard } from "../../components/PublishedLibraryItemCard";
import { PublishedLibraryItemsHeader } from "../../components/PublishedLibraryItemsHeader";

import messages from "./messages";

export const PublishedLibraryItemsPage: React.FC = () => {
    const { itemType } = useParams<{ itemType: LibraryItemType }>();
    const informUser = useInformUser();

    const formattedLibraryItemMessage = itemType.startsWith("accenture_") ? "Accenture dashboard" : itemType;
    useSetBreadcrumbs(fm(messages.publishedLibraryItems, { itemType: formattedLibraryItemMessage }).toString());

    const [searchTerm, setSearchTerm] = useState<string>();

    const [publishedLibraryItemsResult] = useLibraryItemsResults(
        undefined,
        {},
        {
            getParams: {
                itemType,
                onlyShowSelfPublished: true,
            },
        },
        undefined,
        { service: "dashboards" }
    );

    const publishedLibraryItems = publishedLibraryItemsResult?.filter(
        (item) =>
            item.itemType === itemType && (!searchTerm || item.title.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleSearch = (_searchTerm: string) => setSearchTerm(_searchTerm);

    const onUpdate = (successMessage: string) => {
        informUser({ message: successMessage, type: "success" });
        setSearchTerm(undefined);
    };

    if (!publishedLibraryItems || publishedLibraryItems.length === 0) {
        return (
            <Container>
                <PublishedLibraryItemsHeader libraryItemType={itemType} onSearch={handleSearch} />
                <Stack direction="row" alignItems="center" p={2}>
                    <Typography color="lightDark" variant="body1">
                        {fm(messages.noLibraryItemsPublished)}
                    </Typography>
                </Stack>
            </Container>
        );
    }

    return (
        <Container>
            <PublishedLibraryItemsHeader libraryItemType={itemType} onSearch={handleSearch} />
            <Stack direction="row" pl={2}>
                <Grid container spacing={2}>
                    {publishedLibraryItems.map((item, index: number) => (
                        <Grid item xs={4} key={item.id} padding={index > 2 ? [2, 0, 0, 2] : [0.5, 0, 0, 2]}>
                            <PublishedLibraryItemCard libraryItem={item} onUpdate={onUpdate} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </Container>
    );
};
