import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { SearchBar } from "@/features/libraryOverview/components/SearchBar";
import { getLibraryUrl } from "@/features/libraryOverview/helpers";
import { fm } from "@/providers/IntlContextProvider";
import { LibraryItemType } from "@/types/libraryItem";

import messages from "./messages";

interface Props {
    onSearch: (searchTerm: string) => void;
    libraryItemType: LibraryItemType;
}

export const PublishedLibraryItemsHeader: React.FC<Props> = ({ onSearch, libraryItemType }) => {
    const browserHistory = useHistory();
    const path = useLocation().pathname;
    const formattedLibraryItemMessage = libraryItemType.startsWith("accenture_")
        ? "Accenture dashboard"
        : libraryItemType;
    return (
        <Container>
            <Stack direction="row" alignItems="center" p={1}>
                <Stack
                    sx={{ cursor: "pointer" }}
                    onClick={() => browserHistory.push(getLibraryUrl(path, libraryItemType))}
                >
                    <Stack direction="row">
                        <ChevronLeftIcon />
                        <Typography variant="subtitle1">{fm(messages.backToLibrary)}</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack p={2} direction="row" justifyContent="space-between">
                <Stack alignItems="center" sx={{ minWidth: "fit-content", paddingRight: "2em" }}>
                    <Typography variant="h5">{fm(messages.published, { formattedLibraryItemMessage })}</Typography>
                </Stack>
                <Stack alignItems="flex-end">
                    <SearchBar onSearch={onSearch} />
                </Stack>
            </Stack>
        </Container>
    );
};
