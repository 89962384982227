import { defineMessages } from "react-intl";

const messages = defineMessages({
    name: {
        id: "libraryModule.publishDashboardPage.components.name",
        defaultMessage: "Name",
    },
    fieldName: {
        id: "libraryModule.publishDashboardPage.components.fieldName",
        defaultMessage: "Field name",
    },
    fieldType: {
        id: "libraryModule.publishDashboardPage.components.fieldType",
        defaultMessage: "Field type",
    },
    globalType: {
        id: "libraryModule.publishDashboardPage.components.globalType",
        defaultMessage: "Global type",
    },
    assignGlobalTypes: {
        id: "libraryModule.publishDashboardPage.components.assignGlobalTypes",
        defaultMessage: "You have to assign the missing global types prior to publishing.",
    },
    missingGlobalTypeError: {
        id: "libraryModule.publishDashboardPage.components.missingGlobalTypeError",
        defaultMessage: "You must select a global type.",
    },
    duplicateGlobalTypeError: {
        id: "libraryModule.publishDashboardPage.components.duplicateGlobalTypeError",
        defaultMessage: "You cannot assign the same global type to multiple fields",
    },
    table: {
        id: "libraryModule.publishDashboardPage.components.table",
        defaultMessage: "Table",
    },
    goBackButton: {
        id: "libraryModule.publishDashboardPage.components.goBackButton",
        defaultMessage: "Back",
    },
    nextButton: {
        id: "libraryModule.publishDashboardPage.components.nextButton",
        defaultMessage: "Next",
    },
    noFieldsToConfigure: {
        id: "libraryModule.publishDashboardPage.components.noFieldsToConfigure",
        defaultMessage: "No fields to configure, please continue",
    },
    failedToUpdateGlobalTypes: {
        id: "libraryModule.publishDashboardPage.components.failedToUpdateGlobalTypes",
        defaultMessage: "Failed to update global types",
    },
});

export default messages;
