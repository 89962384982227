import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Container, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

import { getFormattedCollectionName } from "@/features/libraryOverview/helpers";
import { LibraryCollection, LibraryItem } from "@/types/libraryItem";

import { LibraryItemCard } from "../LibraryItemCard";

interface Props {
    collection: LibraryCollection;
    libraryItems: LibraryItem[];
    onCollectionSelected: (updatedCollection?: LibraryCollection) => void;
}

export const CollectionPreview: React.FC<Props> = ({ collection, libraryItems, onCollectionSelected }) => {
    const collectionItems = libraryItems.filter((item) => item.collections.includes(collection.id));
    const relevantItems = collectionItems.length > 3 ? collectionItems.slice(0, 3) : collectionItems;

    return relevantItems.length === 0 ? null : (
        <Container sx={{ paddingBottom: 1 }}>
            <Stack direction="row" p={1}>
                <Stack
                    direction="row"
                    sx={{ cursor: "pointer" }}
                    gap={0.5}
                    onClick={() => onCollectionSelected(collection)}
                >
                    <Typography variant="textMd" fontWeight={600}>
                        {getFormattedCollectionName(collection.name)}
                    </Typography>
                    <Typography variant="textMd" fontWeight={600}>
                        {`(${collection.count.toString()})`}
                    </Typography>
                    <ChevronRightIcon />
                </Stack>
            </Stack>
            <Divider />
            <Grid container spacing={2} pt={1}>
                {relevantItems.map((item) => (
                    <Grid item xs={11 / 3} key={item.id} padding={[0.5, 0, 0, 2]}>
                        <LibraryItemCard libraryItem={item} />
                    </Grid>
                ))}
                <Grid item xs={1} padding={[0.5, 0, 0, 2]} alignSelf="center">
                    <IconButton onClick={() => onCollectionSelected(collection)}>
                        <ChevronRightIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Container>
    );
};
