import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { getCustomDashboard } from "@/features/publishing/api/dashboards";
import { BackgroundStep, ConfigureGlobalTypesStep, ReviewStep, WidgetStep } from "@/features/publishing/components";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { fm } from "@/providers/IntlContextProvider";
import { DashboardLibraryItemData } from "@/types/libraryItem";

import { EMPTY_STATE } from "./constants";
import messages from "./messages";

export const PublishDashboardPage: React.FC = () => {
    useSetBreadcrumbs(fm(messages.publishBreadcrumb).toString());

    const [activeStep, setActiveStep] = useState<0 | 1 | 2 | 3>(0);
    const [newLibraryItem, setNewLibraryItem] = useState<DashboardLibraryItemData>(EMPTY_STATE);

    const { dashboardId } = useParams<{ dashboardId: string }>();
    const isAccenture = useFeatureToggle("library-accenture-dashboards");

    const browserHistory = useHistory();

    useEffect(() => {
        getCustomDashboard(Number(dashboardId)).then((customDashboard) => {
            setNewLibraryItem((prevState) => ({
                ...prevState,
                itemId: dashboardId,
                title: customDashboard.name,
                widgets: customDashboard.widgets,
            }));
        });
    }, [dashboardId, setNewLibraryItem]);

    const renderStep = useMemo(() => {
        if (!newLibraryItem) return;

        switch (activeStep) {
            case 0:
                return (
                    <BackgroundStep
                        newLibraryItem={newLibraryItem}
                        setNewLibraryItem={setNewLibraryItem}
                        onBack={() => browserHistory.goBack()}
                        onNext={() => setActiveStep(1)}
                        isAccenture={isAccenture}
                    />
                );
            case 1:
                return (
                    <WidgetStep
                        newLibraryItem={newLibraryItem}
                        setNewLibraryItem={setNewLibraryItem}
                        onBack={() => setActiveStep(0)}
                        onNext={() => setActiveStep(2)}
                    />
                );
            case 2:
                return (
                    <ConfigureGlobalTypesStep
                        newLibraryItem={newLibraryItem}
                        onBack={() => setActiveStep(1)}
                        onNext={() => setActiveStep(3)}
                    />
                );
            case 3:
            default:
                return (
                    <ReviewStep
                        newLibraryItem={newLibraryItem}
                        setStep={setActiveStep}
                        onBack={() => setActiveStep(2)}
                        isAccenture={isAccenture}
                    />
                );
        }
    }, [newLibraryItem, activeStep, isAccenture, browserHistory]);

    return (
        <Stack p={3}>
            <Stack maxWidth="1000px" gap={2}>
                <Stepper activeStep={activeStep} orientation="horizontal">
                    {[
                        fm(messages.fillOutDashboardDetails),
                        fm(messages.configureDescriptions),
                        fm(messages.configureGlobalTypes),
                        fm(messages.reviewAndPublish),
                    ].map((label, index) => {
                        const stepProps: { completed?: boolean } = { completed: index < activeStep };
                        return (
                            <Step key={label.toString()} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {renderStep}
            </Stack>
        </Stack>
    );
};
