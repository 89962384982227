import { Api } from "@ignite-analytics/api-client";
import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";

import { LibraryItem, LibraryItemType } from "@/types/libraryItem";

const listCreateResource = createListResource<LibraryItem>`/library/items/`;
const detailResource = createDetailResource<LibraryItem>`/library/items/${(params) => params.itemType || ""}/${(
    params
) => params.id}/`;

export const {
    Provider: LibraryItemProvider,
    useAll: useAllLibraryItems,
    useResults: useLibraryItemsResults,
    useCreateAction: useCreateLibraryItem,
    useUpdateAction: useUpdateLibraryItem,
    useDeleteAction: useDeleteLibraryItem,
    useThisContext: useLibraryItemContext,
} = createEntityContext(listCreateResource, detailResource, {
    interchangableTypes: true,
    name: "Library items",
});

export const useUpdateLibraryItemCoverImage = () => {
    const { addDetailObj, _isMocked } = useLibraryItemContext();

    return async (id: number, itemType: LibraryItemType, coverImage: File): Promise<LibraryItem | undefined> => {
        const formData = new FormData();
        formData.append("coverimage", coverImage);
        formData.append("itemType", itemType);
        formData.append("id", id.toString());

        if (_isMocked) {
            return new Promise<LibraryItem>(() => {
                /* This promise should never resolve */
            });
        }

        const url = await detailResource._getUrl(detailResource.hooks?.map((hook) => hook({ id, itemType })) ?? []);
        if (!url) return;
        return Api.patch<LibraryItem>(url, formData as unknown as LibraryItem, {
            raw: true,
            service: "dashboards",
        }).then((response) => {
            addDetailObj({ ...response }, url);
            return response;
        });
    };
};
