import { defineMessages } from "react-intl";

const messages = defineMessages({
    cancelButton: {
        id: "components.dialog.confirmDialog.cancelButton",
        defaultMessage: "Cancel",
    },
    confirmButton: {
        id: "components.dialog.confirmDialog.confirmButton",
        defaultMessage: "Confirm",
    },
});

export default messages;
