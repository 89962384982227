import { defineMessages } from "react-intl";

const messages = defineMessages({
    close: {
        id: "components.dialog.successDialog.close",
        defaultMessage: "Close",
    },
    continueButton: {
        id: "components.dialog.successDialog.continueButton",
        defaultMessage: "Continue",
    },
});

export default messages;
