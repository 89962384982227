import { defineMessages } from "react-intl";

const messages = defineMessages({
    header: {
        id: "features.libraryOverview.components.libraryHeader.library",
        defaultMessage: "Library",
    },
    widgets: {
        id: "features.libraryOverview.components.libraryHeader.widgets",
        defaultMessage: "Widgets",
    },
    dashboards: {
        id: "features.libraryOverview.components.libraryHeader.dashboards",
        defaultMessage: "Dashboards",
    },
    accentureDashboards: {
        id: "features.libraryOverview.components.libraryHeader.accentureDashboards",
        defaultMessage: "Accenture dashboards",
    },
    myPublishedDashboards: {
        id: "features.libraryOverview.components.libraryHeader.myPublishedDashboards",
        defaultMessage: "My published dashboards",
    },
    myPublishedAccentureDashboards: {
        id: "features.libraryOverview.components.libraryHeader.myPublishedAccentureDashboards",
        defaultMessage: "My published Accenture dashboards",
    },
});

export default messages;
