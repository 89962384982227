import { defineMessages } from "react-intl";

export const messages = defineMessages({
    somethingWentWrong: {
        id: "providers.sentryLoggingProvider.somethingWentWrong",
        defaultMessage:
            "Our team has been informed. You can attempt to refresh the page. If the issue persists, please reach out to our support team for assistance.",
    },
    error: {
        id: "providers.sentryLoggingProvider.error",
        defaultMessage: "Something went wrong!",
    },
    refresh: {
        id: "providers.sentryLoggingProvider.refresh",
        defaultMessage: "Refresh page",
    },
});
