import { track } from "@ignite-analytics/track";
import Close from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FullWidthImg } from "@/components/FullWidthImg";
import { PermissionContainer } from "@/components/PermissionContainer";
import { IMAGE_SOURCE_PREFIX } from "@/constants";
import { CustomDashboard } from "@/entities/dashboards";
import {
    getGlobalTypeConfigTemplateFromLibrary,
    importDashboardFromLibrary,
    PreservedGlobalTypes,
} from "@/features/importing/api/importing";
import { ConfigureGlobalTypesDialogForDashboard } from "@/features/importing/components";
import { useInformUser } from "@/hooks/useInformUser";
import { fm } from "@/providers/IntlContextProvider";
import { AccentureDashboardLibraryItem, DashboardLibraryItem } from "@/types/libraryItem";

import { AddDashboardToCollection } from "../../components/AddDashboardToCollection";

import messages from "./messages";

interface Props {
    open: boolean;
    onClose: () => void;
    libraryItem: DashboardLibraryItem | AccentureDashboardLibraryItem;
}

export const ImportDashboardModal: React.FC<Props> = ({ open, onClose, libraryItem }) => {
    const dashboardImport = {
        id: libraryItem.id,
        title: libraryItem.title,
        itemType: libraryItem.itemType,
    };
    const [loading, setLoading] = useState(false);
    const informUser = useInformUser();
    const [openGlobalTypeConfigModal, setOpenGlobalTypeConfigModal] = useState(false);
    const [preservedGlobalTypes, setPreservedGlobalTypes] = useState<PreservedGlobalTypes[]>();
    const [openDashboardCollection, setOpenDashboardCollection] = useState(false);
    const [newDashboard, setNewDashboard] = useState<CustomDashboard>();

    const handleImportDashboard = () => {
        setLoading(true);
        getGlobalTypeConfigTemplateFromLibrary(dashboardImport).then((response) => {
            // if no configuration is needed publish directly

            track("importDashboardFromLibrary", {
                configurationNeeded: response.configurationNeeded,
                title: libraryItem.title,
            });
            if (response.configurationNeeded) {
                setOpenGlobalTypeConfigModal(true);
                setPreservedGlobalTypes(response.data);
                setLoading(false);
            } else {
                importDashboardFromLibrary(dashboardImport)
                    .then((customDashboard) => {
                        setLoading(false);
                        setNewDashboard(customDashboard);
                        setOpenDashboardCollection(true);
                    })
                    .catch(() => {
                        setLoading(false);
                        informUser({ message: fm(messages.failedToImportDashboard).toString(), type: "error" });
                    });
            }
        });
    };

    return (
        <>
            <Dialog
                open={open && !openDashboardCollection}
                title={libraryItem.title}
                onClose={onClose}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>
                    <Stack p={1} pt={2} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">{libraryItem.title}</Typography>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack p={1} direction="row" justifyContent="space-between" alignItems="center">
                        <Stack pr={2}>
                            <Stack direction="row" p={0.5}>
                                <Typography variant="subtitle1" fontWeight={700}>{`${fm(
                                    messages.description
                                )}:`}</Typography>
                            </Stack>
                            <Stack direction="row" p={0.5}>
                                <Typography variant="body1"> {libraryItem.description}</Typography>
                            </Stack>
                            {libraryItem.helpCenterLink && (
                                <Link to={{ pathname: libraryItem.helpCenterLink }} target="_blank" rel="noreferrer">
                                    <Stack direction="row" justifyItems="flex-start" alignItems="center" pb={1} pt={2}>
                                        <Stack>
                                            <Typography variant="body2" noWrap>
                                                {fm(messages.helpCenterArticle)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Link>
                            )}
                        </Stack>
                        <Stack>
                            <PermissionContainer
                                requiredPermissionTypes={["add"]}
                                model="customdashboard"
                                equivalentUserPermission={{
                                    namespace: "general",
                                    relation: { object: "general", relation: "write" },
                                }}
                            >
                                <LoadingButton
                                    size="large"
                                    type="button"
                                    variant="contained"
                                    loading={loading}
                                    onClick={handleImportDashboard}
                                >
                                    <Typography noWrap>{fm(messages.useDashboardTemplate)}</Typography>
                                </LoadingButton>
                            </PermissionContainer>
                        </Stack>
                    </Stack>
                    <Stack direction="row" pb={2}>
                        <Accordion sx={{ width: "100%", padding: 0 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Typography variant="textMd" fontWeight={600}>
                                        {fm(messages.widgetDetails)}
                                    </Typography>
                                    <Typography variant="body2" color="gray">
                                        {fm(messages.clickToOpen)}
                                    </Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack px={1} sx={{ width: "100%" }}>
                                    <Grid container>
                                        <Grid item xs={4} pb={0.5}>
                                            <Typography variant="subtitle1" fontWeight={700}>
                                                {fm(messages.widgetName)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} pb={0.5}>
                                            <Typography variant="subtitle1" fontWeight={700}>
                                                {fm(messages.description)}
                                            </Typography>
                                        </Grid>
                                        {libraryItem.widgets.map((widget) => (
                                            <>
                                                <Grid item xs={4} key={`${widget.id}-title`} mt={1}>
                                                    <Typography>{widget.title}</Typography>
                                                </Grid>
                                                <Grid item xs={6} key={`${widget.id}-description`} mt={1}>
                                                    <Typography color="gray">
                                                        {widget.description ?? fm(messages.noDescription)}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        ))}
                                    </Grid>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                    <Stack direction="row" pb={1}>
                        <FullWidthImg
                            src={IMAGE_SOURCE_PREFIX + libraryItem.coverimage}
                            alt={fm(messages.exampleTemplateAltText).toString()}
                            crossOrigin="anonymous"
                        />
                    </Stack>
                </DialogContent>
                {preservedGlobalTypes && (
                    <ConfigureGlobalTypesDialogForDashboard
                        open={openGlobalTypeConfigModal}
                        onClose={() => {
                            setOpenGlobalTypeConfigModal(false);
                            setLoading(false);
                        }}
                        libraryItem={libraryItem}
                        preservedGlobalTypes={preservedGlobalTypes}
                    />
                )}
            </Dialog>
            <AddDashboardToCollection dashboard={newDashboard} open={openDashboardCollection} />
        </>
    );
};
