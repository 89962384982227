import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button, Stack } from "@mui/material";
import React from "react";

import { DropArea } from "./DropArea";

interface Props {
    name: string;
    id: string;
    required?: boolean;
    title?: string | JSX.Element;
    fileTypes?: string[];
    onChange: (file: File) => void;
    loading?: boolean;
    showDropField?: boolean;
    dropAreaLabel?: string;
}

export const UploadField: React.FC<Props> = ({
    name = "input",
    id = "input",
    required = false,
    title = "Upload file",
    fileTypes = [".csv", ".xlsx", ".xls", ".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png", ".svg", ".mp4", ".mov"],
    showDropField = false,
    onChange,
    loading,
    dropAreaLabel,
}) => {
    const onFiles = (files: FileList | (File | null)[] | null) => {
        if (loading) return;
        let outputFiles = files || [];
        outputFiles = Array.from(outputFiles);
        onChange(outputFiles.filter((f) => !!f)[0] as File);
    };
    return (
        <Stack sx={{ width: "100%" }}>
            <Stack direction="row" alignItems="stretch">
                {showDropField && <DropArea onChange={(files) => !loading && onChange(files)} text={dropAreaLabel} />}
            </Stack>
            <Stack direction="row" pt={1}>
                <Button component="label" disabled={loading} endIcon={<FileUploadIcon />}>
                    {title}
                    <input
                        id={id}
                        name={name}
                        type="file"
                        onChange={(e) => onFiles(e.target.files)}
                        required={required}
                        accept={fileTypes.join(",")}
                        hidden
                    />
                </Button>
            </Stack>
        </Stack>
    );
};
