import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";

import { fm } from "@/providers/IntlContextProvider";

import messages from "./messages";

interface Props {
    /* The title of the dialog */
    title: string;
    /* If the dialog is open */
    open: boolean;
    /* Event handler for close events for the dialog */
    onClose: () => void;
    /* Event handler for confirm events for the dialog */
    onConfirm: () => void;
    /* If disabled due to loading or other reasons */
    disabled?: boolean;
    /* The main text of the dialog which descibes what is about to be confirmed */
    confirmText: string;
    /* Text for confirmation button, defaults to "Confirm" */
    confirmButtonText?: string;
}

export const ConfirmDialog: React.VFC<Props> = ({
    open,
    onClose,
    title,
    onConfirm,
    disabled,
    confirmText,
    confirmButtonText,
}) => {
    return (
        <Dialog title={title} open={open} onClose={onClose}>
            <DialogContent>
                <Stack direction="row" justifyItems="center" alignItems="center" padding={2}>
                    <Stack>
                        <Typography variant="h6">{confirmText}</Typography>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack padding={{ right: "2xs" }}>
                    <Button disabled={disabled} color="secondary" onClick={onClose}>
                        {fm(messages.cancelButton)}
                    </Button>
                </Stack>
                <Stack>
                    <Button disabled={disabled} onClick={onConfirm}>
                        {confirmButtonText?.toString() || fm(messages.confirmButton)}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
