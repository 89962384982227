import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";

interface DashboardCollection {
    name: string;
    id: number;
    precedence: number;
}

const listCreateResource = createListResource<DashboardCollection>`/dashboards/dashboard-collections/`;

const detailResource = createDetailResource<DashboardCollection>`/dashboards/dashboard-collections`;

export const { Provider: DashboardCollectionContextProvider, useAll: useAllDashboardCollections } = createEntityContext(
    listCreateResource,
    detailResource,
    {
        interchangableTypes: true,
        name: "DashboardCollection",
    }
);
