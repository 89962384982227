import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import { Stack } from "@mui/material";
import React from "react";

import { InfoMessage } from "@/components/InfoMessage";
import { AppRoutes } from "@/routes";

import { useSessionContext } from "./providers/sessionContextProvider";

export const App: React.FC = () => {
    const user = useSessionContext();
    if (!user) return null;
    return (
        <FeatureToggleContextProvider userEmail={user.email} tenant={user.tenant}>
            <>
                <Stack height="100%" width="100%">
                    <AppRoutes />
                </Stack>
                <InfoMessage />
            </>
        </FeatureToggleContextProvider>
    );
};
