import { GlobalType } from "@ignite-analytics/global-types";
import { getLocale } from "@ignite-analytics/locale";
import moment from "moment";
import React, { useEffect } from "react";
import { createIntl, FormattedMessage, IntlProvider, MessageDescriptor } from "react-intl";

import { getMessages } from "../languages";

const localeMoment = moment;

let intl = createIntl({
    locale: getLocale(),
    messages: getMessages(getLocale()),
    onError: () => {
        /* Do nothing */
    },
});

interface Props {
    locale: string;
    children: React.ReactNode;
}

export const IntlContext: React.FC<Props> = ({ locale, children }) => {
    useEffect(() => {
        intl = createIntl({ locale, messages: getMessages(locale) });
        localeMoment.locale(locale);
    }, [locale]);

    return (
        <IntlProvider
            messages={getMessages(locale)}
            locale={locale}
            onError={() => {
                // TODO: Uncomment this code
                // if (e.code !== "MISSING_TRANSLATION") {
                //     console.error(e);
                //     return;
                // }
                // const idMatch = e.message.match(/"[^"]+"/);
                // if (idMatch) {
                //     if (!window.missingMessages) {
                //         window.missingMessages = [];
                //         console.log(
                //             "Got an intl error. Type `missingMessages` in the console to get a list of all missing messages."
                //         );
                //     }
                //     window.missingMessages?.push(idMatch[0]);
                //     return;
                // }
                // console.error(e);
            }}
        >
            {children}
        </IntlProvider>
    );
};

export const fm = (
    descriptor: MessageDescriptor,
    values?: Record<string, string | number | boolean | null | undefined | Date>
) => {
    const element = <FormattedMessage values={values} {...descriptor} />;
    return { ...element, toString: () => intl.formatMessage(descriptor, values) };
};

export const getGlobalTypeName = (globalType: Pick<GlobalType, "name" | "nameNo">) =>
    (intl.locale === "nb-NO" && globalType.nameNo) || globalType.name;

export const currentIntl = () => intl;
