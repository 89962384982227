import { Stringish } from "@ignite-analytics/general-tools";
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import React from "react";

import { fm } from "@/providers/IntlContextProvider";

import messages from "./messages";

interface Props {
    /* If the dialog is open */
    open: boolean;
    /* The title of the success modal, should describe what has been done successfully */
    successTitle: Stringish;
    /* The subtitle of the success modal, could go into further detail */
    successSubTitle?: Stringish;
    /* Event handler for close actions, e.g. go back to a previous location, close the dialog */
    onClose?: () => void;
    /* Event handler for continue actions, e.g. where should we go next? */
    onContinue: () => void;
    /* Button text for the continue button, defaults to "Continue" */
    continueButtonText?: Stringish;
    /* Button text for the close button, defaults to "Close" */
    closeButtonText?: Stringish;
}

export const SuccessDialog: React.VFC<Props> = ({
    open,
    successTitle,
    successSubTitle,
    onClose,
    onContinue,
    continueButtonText,
    closeButtonText,
}) => {
    return (
        <Dialog title={successTitle.toString()} open={open} onClose={onClose}>
            <DialogContent>
                <Stack direction="row" justifyItems="center" alignItems="center" padding={2}>
                    <Typography variant="h6">{successTitle.toString()}</Typography>
                </Stack>
                <Stack direction="row" pl={2}>
                    <Typography variant="body1">{successSubTitle?.toString()}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="flex-end" gap={1} p={2}>
                    {onClose && (
                        <Stack>
                            <Button onClick={onClose}>
                                <Typography variant="buttonMedium" noWrap>
                                    {closeButtonText?.toString() || fm(messages.close)}
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                    <Stack>
                        <Button onClick={onContinue}>
                            <Typography variant="buttonMedium" noWrap>
                                {continueButtonText?.toString() || fm(messages.continueButton)}
                            </Typography>
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
