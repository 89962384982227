import { debounce } from "@ignite-analytics/general-tools";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import React, { useMemo, useState } from "react";

import { fm } from "@/providers/IntlContextProvider";

import messages from "./messages";

interface Props {
    onSearch: (searchTerm: string) => void;
}

export const SearchBar: React.FC<Props> = ({ onSearch }) => {
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useMemo(() => debounce(onSearch, 500), [onSearch]);

    const handleSearch = (newSearch: string) => {
        setSearch(newSearch);
        if (newSearch !== search) {
            debouncedSearch(newSearch);
        }
    };

    return (
        <OutlinedInput
            size="small"
            type="text"
            onChange={(e) => handleSearch(e.target.value)}
            value={search}
            placeholder={fm(messages.searchPlaceHolder).toString()}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton onClick={search?.length ? () => handleSearch("") : undefined} edge="end">
                        {search?.length ? <ClearIcon /> : <SearchIcon />}
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};
