import { Container, styled } from "@mui/material";

export const ListImageContainer = styled("img")`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

export const LinearGradient = styled(Container)`
    background: transparent;
    background-size: contain;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, transparent, #000000);
        opacity: 0.95;
    }
`;

export const BackgroundImageContainer = styled("img")`
    object-fit: cover;
    height: 13vw;
    width: 100%;
`;
