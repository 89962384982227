import EditIcon from "@mui/icons-material/Edit";
import { Button, Chip, Divider, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { ConfirmDialog } from "@/components/Dialog/ConfirmDialog";
import { SuccessDialog } from "@/components/Dialog/SuccessDialog";
import { useAllLibraryCollections } from "@/entities/libraryCollections";
import { useCreateLibraryItem, useUpdateLibraryItemCoverImage } from "@/entities/libraryItems";
import { useInformUser } from "@/hooks/useInformUser";
import { fm } from "@/providers/IntlContextProvider";
import {
    AccentureDashboardLibraryItemType,
    DashboardLibraryItemData,
    DashboardLibraryItemType,
} from "@/types/libraryItem";

import { FullWidthImg } from "../../../../../components/FullWidthImg";
import stepMessages from "../../../routes/PublishDashboardPage/messages";
import messages from "../messages";

interface Props {
    newLibraryItem: DashboardLibraryItemData;
    setStep: (step: 0 | 1 | 2 | 3) => void;
    onBack: () => void;
    isAccenture: boolean;
}

export const ReviewStep: React.FC<Props> = ({ newLibraryItem, setStep, onBack, isAccenture }) => {
    const browserHistory = useHistory();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const informUser = useInformUser();
    const [loading, setLoading] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const createLibraryItem = useCreateLibraryItem();
    const updateLibraryItemCoverImage = useUpdateLibraryItemCoverImage();

    const handlePublishButtonClicked = async () => {
        setLoading(true);

        if (newLibraryItem.coverimage === undefined) {
            setLoading(false);
            return;
        }

        const itemType: typeof AccentureDashboardLibraryItemType | typeof DashboardLibraryItemType = isAccenture
            ? AccentureDashboardLibraryItemType
            : DashboardLibraryItemType;
        await createLibraryItem(
            {
                ...newLibraryItem,
                itemType,
                coverimage: undefined,
            },
            {
                service: "dashboards",
            }
        )
            .then((libraryItem) => {
                if (libraryItem && newLibraryItem.coverimage) {
                    updateLibraryItemCoverImage(libraryItem.id, libraryItem.itemType, newLibraryItem.coverimage[0])
                        .then(() => {
                            setShowConfirmationDialog(false);
                            setShowSuccessDialog(true);
                        })
                        .catch(() => {
                            informUser({ message: fm(messages.couldNotUploadTheImage), type: "error" });
                            setShowConfirmationDialog(false);
                            setShowSuccessDialog(true);
                        });
                }
            })
            .catch(() => {
                informUser({ message: fm(messages.failedToPublishDashboard), type: "error" });
                setLoading(false);
                setShowConfirmationDialog(false);
            });
    };

    const collections = useAllLibraryCollections({ libraryItemType: "dashboard" }, { service: "dashboards" });

    return (
        <>
            <Stack>
                <Stack direction="row" alignItems="center" pb={2}>
                    <Typography variant="h6">{fm(messages.dashboardDetails)}</Typography>
                    <IconButton size="small" color="default" onClick={() => setStep(0)}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
                <Stack direction="row" pb={1} gap={0.5}>
                    <Typography variant="body1" fontWeight={600}>{`${fm(messages.title)}:`}</Typography>
                    <Typography variant="body1">{newLibraryItem.title}</Typography>
                </Stack>
                <Stack direction="row" pb={1}>
                    <Stack>
                        <Typography variant="body1" fontWeight={600}>{`${fm(messages.description)}:`}</Typography>
                        <Typography variant="body1">{newLibraryItem.description}</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" pb={1}>
                    <Stack>
                        <Stack direction="row">
                            <Typography variant="body1" fontWeight={600}>{`${fm(messages.collections)}:`}</Typography>
                        </Stack>
                        <Stack direction="row">
                            {newLibraryItem.collections.map((collection) => {
                                const collectionName = collections
                                    .find((col) => col.id === collection)
                                    ?.name.toString();
                                return (
                                    <Stack key={collection} pr={0.5}>
                                        <Chip
                                            color="primary"
                                            label={
                                                <Typography variant="body2" noWrap>
                                                    {collectionName}
                                                </Typography>
                                            }
                                        />
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Stack>
                </Stack>
                {newLibraryItem.helpCenterLink ? (
                    <Stack direction="row" pb={1} gap={0.5}>
                        <Typography variant="body1" fontWeight={600}>{`${fm(messages.linkToHelpCenter)}:`}</Typography>
                        <Link href={newLibraryItem.helpCenterLink} variant="body1" target="_blank">
                            {newLibraryItem.helpCenterLink}
                        </Link>
                    </Stack>
                ) : null}
                {newLibraryItem.coverimage && (
                    <Stack direction="row" pb={1}>
                        <Stack maxWidth="600px">
                            <Typography variant="body1" fontWeight={600}>
                                {`${fm(messages.coverPhoto)}:`}
                            </Typography>
                            <FullWidthImg
                                src={URL.createObjectURL(newLibraryItem.coverimage[0])}
                                alt="Library item cover image"
                                crossOrigin="anonymous"
                            />
                        </Stack>
                    </Stack>
                )}
                <Stack direction="row" alignItems="center" pt={2} pb={1}>
                    <Stack>
                        <Typography noWrap variant="h6">
                            {fm(stepMessages.configureDescriptions)}
                        </Typography>
                    </Stack>
                    <Stack>
                        <IconButton onClick={() => setStep(1)} size="small">
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                </Stack>
                <Stack direction="row" pb={1}>
                    <Grid container columnGap={0} rowGap={1}>
                        <Grid item xs={4}>
                            <Typography variant="body1">{fm(messages.widgetName)}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body1">{fm(messages.description)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {newLibraryItem &&
                            newLibraryItem.widgets.map((widget) => (
                                <>
                                    <Grid item xs={4}>
                                        <Typography>{widget.title}</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>{widget.description ?? fm(messages.noDescription)}</Typography>
                                    </Grid>
                                </>
                            ))}
                    </Grid>
                </Stack>
                <Stack direction="row" gap={1} pt={2} justifyContent="flex-end">
                    <Stack>
                        <Button color="secondary" disabled={loading} onClick={onBack}>
                            {fm(messages.goBackButton)}
                        </Button>
                    </Stack>
                    <Stack>
                        <Button color="primary" disabled={loading} onClick={() => setShowConfirmationDialog(true)}>
                            {fm(messages.nextButton)}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            {showConfirmationDialog && (
                <ConfirmDialog
                    title={fm(messages.publishDashboard).toString()}
                    open={showConfirmationDialog}
                    onClose={() => setShowConfirmationDialog(false)}
                    confirmText={fm(messages.confirmation).toString()}
                    confirmButtonText={fm(messages.publish).toString()}
                    onConfirm={handlePublishButtonClicked}
                    disabled={loading}
                />
            )}
            {showSuccessDialog && (
                <SuccessDialog
                    open={showSuccessDialog}
                    successTitle={fm(messages.yourDashboardIsNowPublished)}
                    successSubTitle={fm(messages.checkItOut)}
                    continueButtonText={fm(messages.letsGo)}
                    onContinue={() =>
                        isAccenture
                            ? browserHistory.push("/library/accenture_dashboard/")
                            : browserHistory.push("/library/dashboard/")
                    }
                />
            )}
        </>
    );
};
