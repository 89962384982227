import { defineMessages } from "react-intl";

const messages = defineMessages({
    useDashboardTemplate: {
        id: "features.importing.containers.importDashboardModal.useDashboardTemplate",
        defaultMessage: "Use template",
    },
    description: {
        id: "features.importing.containers.importDashboardModal.description",
        defaultMessage: "Description",
    },
    widgetDetails: {
        id: "features.importing.containers.importDashboardModal.widgetDetails",
        defaultMessage: "Widget details",
    },
    widgetName: {
        id: "features.importing.containers.importDashboardModal.widgetName",
        defaultMessage: "Widget name",
    },
    helpCenterArticle: {
        id: "features.importing.containers.importDashboardModal.helpCenterArticle",
        defaultMessage: "Help center article",
    },
    exampleTemplateAltText: {
        id: "features.importing.containers.importDashboardModal.exampleTemplateAltText",
        defaultMessage: "Example of the template in use.",
    },
    noDescription: {
        id: "features.importing.containers.importDashboardModal.noDescription",
        defaultMessage: "No description",
    },
    clickToOpen: {
        id: "features.importing.containers.importDashboardModal.clickToOpen",
        defaultMessage: "(Click to open)",
    },
    failedToImportDashboard: {
        id: "features.importing.containers.importDashboardModal.failedToImportDashboard",
        defaultMessage: "Failed to import dashboard",
    },
});

export default messages;
