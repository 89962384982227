import Close from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { ConfirmDialog } from "@/components/Dialog/ConfirmDialog";
import { useDeleteLibraryItem, useUpdateLibraryItem, useUpdateLibraryItemCoverImage } from "@/entities/libraryItems";
import { useInformUser } from "@/hooks/useInformUser";
import { fm } from "@/providers/IntlContextProvider";
import { LibraryItem } from "@/types/libraryItem";

import { EditableFields } from "../EditableFields";
import { EditLibraryItemDetailView } from "../EditLibraryItemDetailView";

import { updateImageAndPreviewUrl } from "./helpers";
import messages from "./messages";

interface Props {
    open: boolean;
    onClose: () => void;
    libraryItem: LibraryItem;
    onUpdate?: (successMessage: string) => void;
}

export const EditLibraryItemModal: React.FC<Props> = ({ libraryItem, open, onClose, onUpdate }) => {
    const updateLibraryItemCoverImage = useUpdateLibraryItemCoverImage();
    const updateLibraryItem = useUpdateLibraryItem({ id: libraryItem.id, itemType: libraryItem.itemType });
    const deleteLibraryItem = useDeleteLibraryItem({ id: libraryItem.id, itemType: libraryItem.itemType });

    const informUser = useInformUser();

    const [modifiedLibraryItem, setModifiedLibraryItem] = useState<LibraryItem>(libraryItem);
    const [coverImage, setCoverImage] = useState<File>();
    const [previewImageUrl, setPreviewImageUrl] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);

    const handleImageFileSelected = (file?: File) => updateImageAndPreviewUrl(file, setCoverImage, setPreviewImageUrl);

    const handleSaveButtonClicked = () => {
        setIsLoading(true);
        let coverImagePromise: Promise<LibraryItem | undefined | void> = new Promise<undefined>((res) =>
            res(undefined)
        );
        if (coverImage) {
            coverImagePromise = updateLibraryItemCoverImage(libraryItem.id, libraryItem.itemType, coverImage).catch(
                () => {
                    informUser({ message: fm(messages.libraryItemUpdateFailure).toString(), type: "error" });
                    setIsLoading(false);
                }
            );
        }

        const { coverimage, ...other } = modifiedLibraryItem;
        coverImagePromise.then(() =>
            updateLibraryItem({ ...other }, { service: "dashboards" })
                .then(() => {
                    onUpdate && onUpdate(fm(messages.libraryItemUpdateSuccess).toString());
                    onClose();
                })
                .catch(() => {
                    informUser({ message: fm(messages.libraryItemUpdateFailure).toString(), type: "error" });
                })
                .finally(() => setIsLoading(false))
        );
    };

    const handleDeleteButtonClicked = async () => {
        setIsLoading(true);
        deleteLibraryItem({ service: "dashboards" })
            .then(() => {
                onUpdate && onUpdate(fm(messages.libraryItemDeleteSuccess).toString());
            })
            .catch(() => informUser({ message: fm(messages.libraryItemDeleteFailure).toString(), type: "error" }))
            .finally(() => {
                setIsLoading(false);
                onClose();
            });
    };

    return (
        <>
            <Dialog title={fm(messages.editLibraryItem).toString()} open={open} onClose={onClose} maxWidth="lg">
                <DialogContent>
                    <Stack p={1} pt={2} direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">{fm(messages.editLibraryItem)}</Typography>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <Stack direction="row">
                        <Grid container>
                            <Grid item xs={6}>
                                <EditableFields
                                    modifiedLibraryItem={modifiedLibraryItem}
                                    coverImage={coverImage}
                                    onImageFileSelected={handleImageFileSelected}
                                    onModifiedLibraryItemUpdated={setModifiedLibraryItem}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">{fm(messages.preview)}</Typography>
                                <EditLibraryItemDetailView
                                    libraryItem={modifiedLibraryItem}
                                    previewImageUrl={previewImageUrl}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" gap={1}>
                        <Button color="error" disabled={isLoading} onClick={() => setShowDeletePrompt(true)}>
                            {fm(messages.deleteButton)}
                        </Button>
                        <Button color="secondary" disabled={isLoading} onClick={handleSaveButtonClicked}>
                            {fm(messages.saveButton)}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            {showDeletePrompt && (
                <ConfirmDialog
                    open={showDeletePrompt}
                    confirmText={fm(messages.sureAboutDeletion).toString()}
                    onConfirm={() => handleDeleteButtonClicked()}
                    onClose={() => setShowDeletePrompt(false)}
                    title={fm(messages.deleteLibraryDashboard).toString()}
                />
            )}
        </>
    );
};
