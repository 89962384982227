import { Grid, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { APP_BAR_HEIGTH_PX } from "@/constants";
import { useAllLibraryItems } from "@/entities/libraryItems";
import { LibraryCollectionMenu } from "@/features/collectionMenu/containers/LibraryCollectionMenu";
import { LibraryHeader } from "@/features/libraryOverview/components";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import {
    AccentureDashboardLibraryItemType,
    DashboardLibraryItemType,
    LibraryCollection,
    LibraryItemType,
} from "@/types/libraryItem";

import { LibraryListContainer } from "../../containers/LibraryListContainer";

export const LibraryMainPage: React.FC = () => {
    const [selectedCollection, setSelectedCollection] = useState<LibraryCollection>();
    useSetBreadcrumbs();
    const { tab } = useParams<{ tab: LibraryItemType }>();

    const currentTab = useRef(tab);
    if (currentTab.current !== tab) {
        setSelectedCollection(undefined);
        currentTab.current = tab;
    }

    const [searchTerm, setSearchTerm] = useState<string | undefined>();

    const libraryItems = useAllLibraryItems(undefined, {
        getParams: {
            itemType: tab,
            ...(searchTerm ? { search: searchTerm } : {}),
        },
        service: undefined,
    }).filter(
        (item) => item.itemType === tab && (!searchTerm || item.title.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleSearch = (_searchTerm: string) => {
        setSearchTerm(_searchTerm);
        setSelectedCollection(undefined);
    };

    const handleCollectionSelected = (updatedCollection: LibraryCollection | undefined) => {
        setSelectedCollection(updatedCollection);
        setSearchTerm(undefined);
    };

    const supportedLibraryItemTypes = [DashboardLibraryItemType, AccentureDashboardLibraryItemType];

    if (!supportedLibraryItemTypes.includes(currentTab.current)) {
        return null;
    }

    return (
        <Grid container direction="row" spacing={2} sx={{ minHeight: `calc(100vh - ${APP_BAR_HEIGTH_PX}px)` }}>
            <Grid item>
                <LibraryCollectionMenu
                    onCollectionSelected={handleCollectionSelected}
                    selectedCollection={selectedCollection}
                />
            </Grid>
            <Grid item xs>
                <Stack direction="row">
                    <LibraryHeader onSearch={handleSearch} />
                </Stack>
                <Stack pt={1} direction="row" alignItems="stretch">
                    <LibraryListContainer
                        collection={selectedCollection}
                        onCollectionSelected={handleCollectionSelected}
                        libraryItems={libraryItems ?? []}
                        isLoading={libraryItems === undefined}
                        isSearch={!!searchTerm}
                    />
                </Stack>
            </Grid>
        </Grid>
    );
};
