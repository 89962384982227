import { DashboardLibraryItemData } from "@/types/libraryItem";

export const EMPTY_STATE: DashboardLibraryItemData = {
    modelType: "dashboard",
    title: "",
    description: "",
    itemType: "dashboard",
    collections: [],
    itemId: "",
    helpCenterLink: "",
    widgets: [],
};
