import { Api } from "@ignite-analytics/api-client";

import { CustomDashboard } from "@/types/dashboard";

/**
 *
 * Retrieves a specific custom dashboard
 *
 * @param {number} customDashboardId: number
 * @param {boolean} isADefault: Whether the dashboard is a default or a custom
 * @returns {Promise<CustomDashboard>}
 */

export function getCustomDashboard(customDashboardId: number): Promise<CustomDashboard> {
    const dashboardsUrl = `/dashboards/${customDashboardId}/`;
    const url = dashboardsUrl;

    return Api.get(url, { service: "dashboards" });
}
