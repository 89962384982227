import { GlobalColumnType, GlobalGroupTypeType, GlobalType } from "@ignite-analytics/global-types";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Divider, Grid, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

import messages from "@/features/importing/components/ConfigureGlobalTypesDialogForDashboard/messages";
import { DataColumn } from "@/generated/client";
import { fm } from "@/providers/IntlContextProvider";
import { GlobalTypeMapping, UpdateableField } from "@/types/globalTypeMapping";

import { getMappingKey, getSelectableFields } from "./helpers";

interface Props {
    globalTypesToConfigure: GlobalType[];
    globalTypeMapping: GlobalTypeMapping;
    setGlobalTypeMapping: React.Dispatch<React.SetStateAction<GlobalTypeMapping>>;
    dataColumns: DataColumn[];
}

export const SelectGlobalColumnTypesTable: React.FC<Props> = ({
    globalTypesToConfigure,
    globalTypeMapping,
    setGlobalTypeMapping,
    dataColumns,
}) => {
    const handleDataGroupSelected = (valueId: string, globalType: GlobalType) => {
        let value: DataColumn | undefined;

        getSelectableFields(globalType, dataColumns, globalTypeMapping).forEach((field) => {
            if (field.id.toString() === valueId) {
                value = field;
            }
        });
        if (value) {
            setGlobalTypeMapping(
                (prevState) =>
                    prevState && {
                        ...prevState,
                        [getMappingKey(globalType)]: {
                            ...value,
                            globalType: globalType.id,
                            globalTypeKey: globalType.key,
                            updated: true,
                        } as UpdateableField,
                    }
            );
        } else {
            setGlobalTypeMapping((prevState) => {
                const copy = { ...prevState };
                delete copy[getMappingKey(globalType)];
                return copy;
            });
        }
    };

    return (
        <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }} p={2}>
            <Grid container rowGap={2}>
                <Grid item xs={3}>
                    {fm(messages.globalTypeTableHeader)}
                </Grid>
                <Grid item xs={3}>
                    {fm(messages.descriptionTableHeader)}
                </Grid>
                <Grid item xs={2}>
                    {fm(messages.dataTypeTableHeader)}
                </Grid>
                <Grid item xs={4}>
                    {fm(messages.fieldSourceTableHeader)}
                </Grid>
                <Grid item xs={12} mt={-1}>
                    <Divider />
                </Grid>
                {globalTypesToConfigure.map((globalType: GlobalGroupTypeType | GlobalColumnType) => {
                    const selectableFields = getSelectableFields(globalType, dataColumns, globalTypeMapping);
                    return (
                        <Stack direction="row" key={globalType.key} sx={{ width: "100%" }} alignItems="center">
                            <Grid item xs={3}>
                                <Typography>{globalType.name}</Typography>
                            </Grid>
                            <Grid item xs={3} pr={2}>
                                <Typography color="gray">
                                    {globalType.description || fm(messages.noDescription).toString()}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color="gray">
                                    {"dataType" in globalType ? globalType.dataType : "Group"}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                {selectableFields.length ? (
                                    <Select
                                        name={`${globalType.name}-${globalType.id}.globalType`}
                                        onChange={(e) => handleDataGroupSelected(e.target.value as string, globalType)}
                                        placeholder={fm(messages.selectSourceField).toString()}
                                        fullWidth
                                        size="small"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "300px",
                                                },
                                            },
                                        }}
                                    >
                                        {selectableFields.map((dataColumn) => (
                                            <MenuItem key={dataColumn.id} value={dataColumn.id.toString()}>
                                                {dataColumn.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ) : (
                                    <Tooltip title={fm(messages.noFieldsAvailableTooltip)}>
                                        <Stack direction="row" sx={{ cursor: "pointer" }} alignItems="center" gap={1}>
                                            <Typography color="gray">{fm(messages.noFieldsAvailable)}</Typography>
                                            <HelpOutlineIcon fontSize="small" color="disabled" />
                                        </Stack>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Stack>
                    );
                })}
            </Grid>
        </Stack>
    );
};
