import * as Permissions from "@ignite-analytics/permissions";
import React, { ReactNode } from "react";

import { PermissionRelation, usePermission } from "@/hooks/usePermission";

interface Props {
    requiredPermissionTypes: Permissions.BackendPermissionType[];
    equivalentUserPermission: { namespace: string; relation: PermissionRelation };
    model: Permissions.Model;
    renderOnPermissionDenied?: ReactNode;
    children: ReactNode;
    loader?: JSX.Element | null;
}

export const PermissionContainer: React.FC<Props> = ({
    equivalentUserPermission,
    children,
    renderOnPermissionDenied = null,
}) => {
    const hasOryPermission = usePermission(equivalentUserPermission.namespace, equivalentUserPermission.relation);
    if (!hasOryPermission) return renderOnPermissionDenied as JSX.Element;
    return children as JSX.Element;
};
