import { Chip, Stack, Typography } from "@mui/material";
import React from "react";

import { getFormattedCollectionName } from "@/features/libraryOverview/helpers";
import { fm } from "@/providers/IntlContextProvider";
import { LibraryCollection } from "@/types/libraryItem";

import messages from "./messages";

interface Props {
    libraryItemCollection?: LibraryCollection;
    isSelected: boolean;
    onCollectionSelected: (updatedCollection?: LibraryCollection) => void;
}

export const CollectionSelectButton: React.FC<Props> = ({
    libraryItemCollection,
    isSelected,
    onCollectionSelected,
}) => {
    const collectionName =
        libraryItemCollection === undefined ? fm(messages.all) : getFormattedCollectionName(libraryItemCollection.name);

    return (
        <Stack
            direction="row"
            justifyItems="center"
            padding={1.5}
            sx={{ cursor: "pointer" }}
            onClick={() => {
                onCollectionSelected(libraryItemCollection);
            }}
            gap={2}
        >
            <Stack>
                <Typography noWrap variant="textMd" fontWeight={500}>
                    {isSelected ? <b>{collectionName}</b> : collectionName}
                </Typography>
            </Stack>
            {libraryItemCollection?.count && <Chip color="primary" size="small" label={libraryItemCollection.count} />}
        </Stack>
    );
};
