import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";

import { FileType } from "./style";

interface Props {
    name: string;
    url: string | null;
    onDeleteFile: () => void;
}

export const File: React.FC<Props> = ({ name, url, onDeleteFile }) => {
    const fileSplit = name.split(".");
    const fileType = fileSplit[fileSplit.length - 1];

    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <FileType type={fileType} />
            <Typography fontWeight={600} color="gray">
                {url?.length ? (
                    <a href={url} rel="noopener noreferrer" target="_blank">
                        {name}
                    </a>
                ) : (
                    name
                )}
            </Typography>
            <IconButton onClick={onDeleteFile} size="small">
                <CloseIcon />
            </IconButton>
        </Stack>
    );
};
