import { defineMessages } from "react-intl";

const messages = defineMessages({
    spendOverview: {
        id: "features.libraryOverview.routes.libraryMainPage.spendOverview",
        defaultMessage: "Spend: Overview",
    },
    spendCategories: {
        id: "features.libraryOverview.routes.libraryMainPage.spendCategories",
        defaultMessage: "Spend: Categories",
    },
    spendDevelopment: {
        id: "features.libraryOverview.routes.libraryMainPage.spendDevelopment",
        defaultMessage: "Spend: Development",
    },
    spendDistribution: {
        id: "features.libraryOverview.routes.libraryMainPage.spendDistribution",
        defaultMessage: "Spend: Distribution",
    },
    spendItems: {
        id: "features.libraryOverview.routes.libraryMainPage.spendItems",
        defaultMessage: "Spend: Items",
    },
    spendInvoices: {
        id: "features.libraryOverview.routes.libraryMainPage.spendInvoices",
        defaultMessage: "Spend: Invoices",
    },
    suppliers: {
        id: "features.libraryOverview.routes.libraryMainPage.suppliers",
        defaultMessage: "Suppliers",
    },
    dataDetailsSpend: {
        id: "features.libraryOverview.routes.libraryMainPage.dataDetailsSpend",
        defaultMessage: "Data details: Spend",
    },
    dataDetailsContracts: {
        id: "features.libraryOverview.routes.libraryMainPage.dataDetailsContracts",
        defaultMessage: "Data details: Contracts",
    },
    contracts: {
        id: "features.libraryOverview.routes.libraryMainPage.contracts",
        defaultMessage: "Contracts",
    },
    tasks: {
        id: "features.libraryOverview.routes.libraryMainPage.tasks",
        defaultMessage: "Tasks",
    },
    notifications: {
        id: "features.libraryOverview.routes.libraryMainPage.notifications",
        defaultMessage: "Notifications",
    },
    savingsTracker: {
        id: "features.libraryOverview.routes.libraryMainPage.savingsTracker",
        defaultMessage: "Savings tracker",
    },
    opportunities: {
        id: "features.libraryOverview.routes.libraryMainPage.opportunities",
        defaultMessage: "Opportunities",
    },
    risks: {
        id: "features.libraryOverview.routes.libraryMainPage.risks",
        defaultMessage: "Risks",
    },
    benchmarking: {
        id: "features.libraryOverview.routes.libraryMainPage.benchmarking",
        defaultMessage: "Benchmarking",
    },
    addDashboard: {
        id: "features.libraryOverview.routes.libraryMainPage.addDashboard",
        defaultMessage: "Add dashboard",
    },
    addToDashboard: {
        id: "features.libraryOverview.routes.libraryMainPage.addToDashboard",
        defaultMessage: "Add to dashboard",
    },
});

export default messages;
