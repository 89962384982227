import { defineMessages } from "react-intl";

const messages = defineMessages({
    backToLibrary: {
        id: "features.published.components.publishedLibraryItemsHeader.backToLibrary",
        defaultMessage: "Back to Library",
    },
    published: {
        id: "features.published.components.publishedLibraryItemsHeader.published",
        defaultMessage: "My published {formattedLibraryItemMessage}s",
    },
});

export default messages;
