import Edit from "@mui/icons-material/Edit";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { IMAGE_SOURCE_PREFIX } from "@/constants";
import { ListImageContainer } from "@/features/libraryOverview/routes/LibraryMainPage/style";
import { LibraryItem } from "@/types/libraryItem";

import { EditLibraryItemModal } from "../EditLibraryItemModal";

interface Props {
    libraryItem: LibraryItem;
    onUpdate: (successMessage: string) => void;
}

export const PublishedLibraryItemCard: React.FC<Props> = ({ libraryItem, onUpdate }) => {
    const [showEditLibraryItemModal, setShowEditLibraryItemModal] = useState(false);
    const [hover, setHover] = useState(false);

    return (
        <>
            <Card
                sx={{
                    overflow: "hidden",
                    cursor: "pointer",
                    padding: 0,
                    maxHeight: "15vw",
                    minHeight: "15vw",
                    boxShadow: 2,
                }}
                onClick={() => setShowEditLibraryItemModal(true)}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <CardContent>
                    <Stack direction="row" sx={{ width: "100%" }} justifyContent="space-between">
                        <Stack>
                            <Typography p={1} variant="h6">
                                {libraryItem.title}
                            </Typography>
                        </Stack>
                        <Stack alignItems="flex-end">{hover && <Edit fontSize="small" />}</Stack>
                    </Stack>
                    <Stack direction="row">
                        <ListImageContainer
                            src={IMAGE_SOURCE_PREFIX + libraryItem.coverimage}
                            alt="coverimage"
                            crossOrigin="anonymous"
                        />
                    </Stack>
                </CardContent>
            </Card>
            {showEditLibraryItemModal && (
                <EditLibraryItemModal
                    open={showEditLibraryItemModal}
                    onClose={() => setShowEditLibraryItemModal(false)}
                    libraryItem={libraryItem}
                    onUpdate={onUpdate}
                />
            )}
        </>
    );
};
