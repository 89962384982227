import { isNotNullish } from "@ignite-analytics/general-tools";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";

import { useAllLibraryCollections } from "@/entities/libraryCollections";
import { fm } from "@/providers/IntlContextProvider";
import { AccentureDashboardLibraryItemType, DashboardLibraryItemType, LibraryCollection } from "@/types/libraryItem";

import messages from "../../messages";

interface Option {
    id: number;
    label: string;
}
const mapLibraryCollectionToOption = ({ id, name }: LibraryCollection): Option => ({ id, label: name });

interface Props {
    selectedCollections: number[];
    onChange: (values: number[]) => void;
}

export const AccentureDashboardLibraryCollection: React.FC<Props> = ({ selectedCollections, onChange }) => {
    const libraryCollections = useAllLibraryCollections(
        { libraryItemType: AccentureDashboardLibraryItemType },
        { service: "dashboards" }
    );

    const options = libraryCollections.map(mapLibraryCollectionToOption);
    const selectedCollectionsLabelled: Option[] = selectedCollections
        .map((id) => {
            const matchingLibraryCollection = libraryCollections.find((c) => c.id === id);
            return matchingLibraryCollection ? mapLibraryCollectionToOption(matchingLibraryCollection) : null;
        })
        .filter(isNotNullish);
    return (
        <Autocomplete
            disablePortal
            options={options}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField name="collections" label={fm(messages.collections)} {...params} />}
            value={selectedCollectionsLabelled}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            multiple
            onChange={(e, values) => {
                const collectionIds = values.map(({ id }) => id);
                onChange(collectionIds);
            }}
        />
    );
};

export const DashboardLibraryCollection: React.FC<Props> = ({ selectedCollections, onChange }) => {
    const libraryCollections = useAllLibraryCollections(
        { libraryItemType: DashboardLibraryItemType },
        { service: "dashboards" }
    );
    const options = libraryCollections.map(mapLibraryCollectionToOption);
    const selectedCollectionsLabelled: Option[] = selectedCollections
        .map((id) => {
            const matchingLibraryCollection = libraryCollections.find((c) => c.id === id);
            return matchingLibraryCollection ? mapLibraryCollectionToOption(matchingLibraryCollection) : null;
        })
        .filter(isNotNullish);

    return (
        <Autocomplete
            disablePortal
            options={options}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField name="collections" label={fm(messages.collections)} {...params} />}
            value={selectedCollectionsLabelled}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            multiple
            onChange={(e, values) => {
                const collectionIds = values.map(({ id }) => id);
                onChange(collectionIds);
            }}
        />
    );
};
