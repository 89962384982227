import Close from "@mui/icons-material/Close";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useAllDashboardCollections } from "@/entities/dashboardCollections";
import { CustomDashboard, useUpdateMultipleCustomDashboards } from "@/entities/dashboards";
import { useInformUser } from "@/hooks/useInformUser";
import { fm } from "@/providers/IntlContextProvider";

import messages from "./messages";

interface Props {
    dashboard: CustomDashboard | undefined;
    open: boolean;
}

export const AddDashboardToCollection: React.FC<Props> = ({ dashboard, open }) => {
    const informUser = useInformUser();
    const browserHistory = useHistory();
    const [dashboardCollectionId, setDashboardCollectionId] = useState(-1);
    const collections = useAllDashboardCollections(undefined, {
        service: "dashboards",
    });
    const updateDashboards = useUpdateMultipleCustomDashboards();

    const handleEdit = (id: number) => {
        setDashboardCollectionId(id);
    };

    const redirectToDashboard = () => {
        if (dashboard) browserHistory.push(`/dashboard/overview/${dashboard.id}/`);
    };

    const handleNewDashoardCollection = () => {
        if (dashboard && dashboardCollectionId !== -1) {
            updateDashboards([{ ...dashboard, dashboardCollection: dashboardCollectionId }])
                .then(() => {
                    redirectToDashboard();
                })
                .catch(() => {
                    informUser({ message: fm(messages.failedToAddToCollection), type: "error" });
                });
        }
        redirectToDashboard();
    };

    return (
        <Dialog
            onClose={() => {
                redirectToDashboard();
            }}
            open={open}
            fullWidth={false}
            maxWidth="xs"
        >
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle2">{fm(messages.title)}</Typography>
                    <IconButton onClick={() => redirectToDashboard()}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body2">{fm(messages.explanation)}</Typography>
                <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                    <FormControl sx={{ m: 1, minWidth: 200, alignSelf: "center", justifySelf: "center" }} size="small">
                        <InputLabel id="dashboard-collection-label">{fm(messages.label)}</InputLabel>
                        <Select
                            labelId="dashboard-collection-label"
                            id="dashboard-collection-select"
                            value={dashboardCollectionId}
                            label={fm(messages.label)}
                            onChange={(e) => handleEdit(e.target.value ? Number(e.target.value) : 0)}
                        >
                            {collections.map((collection) => {
                                return (
                                    <MenuItem key={collection.id} value={collection.id}>
                                        {collection.name}
                                    </MenuItem>
                                );
                            })}
                            <MenuItem value={-1} key="otherOption">
                                {fm(messages.other)}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ width: 1, marginBottom: "20px", textAlign: "center" }}>
                    <Button variant="contained" onClick={handleNewDashoardCollection} sx={{ width: "200px" }}>
                        <Typography noWrap>{fm(messages.add)}</Typography>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
