import { defineMessages } from "react-intl";

const messages = defineMessages({
    edit: {
        id: "features.published.components.libraryDetailHeader.edit",
        defaultMessage: "Edit",
    },
});

export default messages;
