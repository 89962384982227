import { fm } from "@/providers/IntlContextProvider";

import messages from "./messages";

export const updateImageAndPreviewUrl = (
    file: File | undefined,
    updateImage: (fileList?: File) => void,
    updateUrl: (url?: string) => void
) => {
    if (file === undefined) {
        updateImage(undefined);
        updateUrl(undefined);
        return;
    }
    const fileName = file.name.toLowerCase();
    if (
        !(
            fileName.endsWith(".png") ||
            fileName.endsWith(".jpg") ||
            fileName.endsWith(".jpeg") ||
            fileName.endsWith(".svg")
        )
    ) {
        alert(fm(messages.pictureFileTypeAlert));
    } else {
        updateImage(file);
        updateUrl(URL.createObjectURL(file));
    }
};
