import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { SIDEBAR_WIDTH } from "@/constants";
import { useAllLibraryCollections } from "@/entities/libraryCollections";
import { CollectionSelectButton } from "@/features/collectionMenu/components";
import { fm } from "@/providers/IntlContextProvider";
import { LibraryCollection, LibraryItemType } from "@/types/libraryItem";

import messages from "./messages";

interface Props {
    selectedCollection?: LibraryCollection;
    onCollectionSelected: (updatedCollection?: LibraryCollection) => void;
}

export const LibraryCollectionMenu: React.FC<Props> = ({ onCollectionSelected, selectedCollection }) => {
    const { tab } = useParams<{ tab: LibraryItemType }>();
    const relevantCollections = useAllLibraryCollections({ libraryItemType: tab }, { service: undefined }).filter(
        ({ libraryItemType, count }) => libraryItemType === tab && count > 0
    );

    const handleCollectionSelected = (updatedCollection?: LibraryCollection) => onCollectionSelected(updatedCollection);

    return (
        <Box
            sx={{
                width: SIDEBAR_WIDTH,
                height: "100%",
                background: "#F7F7F8",
            }}
        >
            <Stack px={2} pt={2} sx={{ maxHeight: "90%", overflowY: "auto" }}>
                <Stack>
                    <Stack direction="row" justifyContent="stretch" pb={1}>
                        <Typography variant="textMd" fontWeight={500}>
                            {fm(messages.collections)}
                        </Typography>
                    </Stack>

                    <CollectionSelectButton
                        libraryItemCollection={undefined}
                        isSelected={selectedCollection === undefined}
                        onCollectionSelected={handleCollectionSelected}
                    />

                    {relevantCollections?.map((collection: LibraryCollection) => (
                        <CollectionSelectButton
                            key={collection.id}
                            libraryItemCollection={collection}
                            isSelected={selectedCollection?.id === collection.id}
                            onCollectionSelected={handleCollectionSelected}
                        />
                    ))}
                </Stack>
            </Stack>
        </Box>
    );
};
