import { Widget } from "./widget";

const WidgetLibraryItemType = "widget";
export const DashboardLibraryItemType = "dashboard";
export const AccentureDashboardLibraryItemType = "accenture_dashboard";

export type LibraryItemType = typeof DashboardLibraryItemType | typeof AccentureDashboardLibraryItemType;

export interface LibraryCollection {
    id: number;
    name: string;
    icon: string;
    libraryItemType: LibraryItemType;
    count: number;
}

export interface SchemaSpecificLibraryItem extends LibraryItemBase {
    templateItem?: number;
}

interface LibraryItemBase {
    id: number;
    title: string;
    description: string;
    coverimage?: string;
    collections: number[];
}

export interface DashboardLibraryItem extends SchemaSpecificLibraryItem {
    itemType: typeof DashboardLibraryItemType;
    widgets: Widget[];
    helpCenterLink?: string;
}
export interface AccentureDashboardLibraryItem extends SchemaSpecificLibraryItem {
    itemType: typeof AccentureDashboardLibraryItemType;
    widgets: Widget[];
    helpCenterLink?: string;
}

export type DashboardLibraryItemData = {
    modelType: "dashboard";
    itemId: string;
    coverimage?: File[];
} & Omit<DashboardLibraryItem | AccentureDashboardLibraryItem, "templateItem" | "id" | "coverimage">;

export interface LibraryItemData {
    id?: number;
    title: string;
    collections: number[];
    description: string;
    coverImage?: File[];
    itemType: typeof WidgetLibraryItemType;
    itemId: string;
    modelType: string;
    department?: number;
    hasTitleBeenSet: boolean;
    elasticIndex?: string;
}

export interface CreateWidgetFromLibraryItemRequestBody {
    title: string;
    dataTable?: number | string;
    dashboard: number | undefined;
    department: number | undefined;
}

export type LibraryItem = DashboardLibraryItem | AccentureDashboardLibraryItem;
