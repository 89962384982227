import { defineMessages } from "react-intl";

const messages = defineMessages({
    other: {
        id: "features.importing.components.addDashboardToCollection.other",
        defaultMessage: "Other",
    },
    title: {
        id: "features.importing.components.addDashboardToCollection.title",
        defaultMessage: "Add dashboard to a collection",
    },
    explanation: {
        id: "features.importing.components.addDashboardToCollection.explanation",
        defaultMessage:
            "You can choose to add this dashboard to your preferred Dashboard collection. If you don't choose a Dashboard collection, the dashboard will be added to the 'Other' collection.",
    },
    label: {
        id: "features.importing.components.addDashboardToCollection.label",
        defaultMessage: "Dashbord Collection",
    },
    add: {
        id: "features.importing.components.addDashboardToCollection.add",
        defaultMessage: "Add",
    },
    failedToAddToCollection: {
        id: "features.importing.components.addDashboardToCollection.failedToAddToCollection",
        defaultMessage: "Failed to add dashboard to collection",
    },
});

export default messages;
