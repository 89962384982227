import { FetchResult } from "@apollo/client";
import { isDataColumnV2, isDataTableV2 } from "@ignite-analytics/data-table";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { getGlobalTypeConfig, setDashboardGlobalTypes } from "@/features/publishing/api/globalTypes";
import {
    UpdateDataColumnMutation,
    UpdateDataTableMutation,
    useUpdateDataColumnMutation,
    useUpdateDataTableMutation,
} from "@/generated/client";
import { useInformUser } from "@/hooks/useInformUser";
import { fm } from "@/providers/IntlContextProvider";
import { ElasticIndexConfig, Field, GlobalTypeMapping } from "@/types/globalTypeMapping";
import { DashboardLibraryItemData } from "@/types/libraryItem";

import { ConfigureGlobalTypeTable } from "./ConfigureGlobalTypeTable";
import { getObjectId } from "./helpers";
import messages from "./messages";

interface Props {
    newLibraryItem: DashboardLibraryItemData;
    onNext: () => void;
    onBack: () => void;
}

export const ConfigureGlobalTypesStep: React.FC<Props> = ({ newLibraryItem, onNext, onBack }) => {
    const [tables, setTables] = useState<Record<number, ElasticIndexConfig>>({});
    const informUser = useInformUser();

    const [globalTypeMapping, setGlobalTypeMapping] = useState<GlobalTypeMapping>();
    const [loading, setLoading] = useState(false);

    const handleResponse = (response: Record<number, ElasticIndexConfig>) => {
        setGlobalTypeMapping(() => ({
            ...Object.fromEntries(
                Object.values(response)
                    .flatMap(({ dataTable, dataColumns }) => [dataTable, ...dataColumns])
                    .map((field) => [getObjectId(field), { ...field, updated: false, required: true }])
            ),
        }));
        setTables(response);
    };

    useEffect(() => {
        if (!newLibraryItem.itemId) return;
        getGlobalTypeConfig(newLibraryItem.itemId).then((response) => handleResponse(response));
    }, [newLibraryItem.itemId]);

    const [updateDataColumn] = useUpdateDataColumnMutation();
    const [updateDataTable] = useUpdateDataTableMutation();

    const handleGlobalColumnTypesSet = () => {
        setDashboardGlobalTypes(newLibraryItem.itemId)
            .then(() => {
                onNext();
            })
            .catch(() => {
                informUser({ message: fm(messages.failedToUpdateGlobalTypes), type: "error" });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = () => {
        /**
         * Filter on updated fields and send requests for the ones that have been updated.
         */
        setLoading(true);
        if (!globalTypeMapping) return;

        const promises: (
            | Promise<
                  | Field
                  | FetchResult<UpdateDataTableMutation, Record<string, unknown>, Record<string, unknown>>
                  | FetchResult<UpdateDataColumnMutation, Record<string, unknown>, Record<string, unknown>>
                  | undefined
              >
            | undefined
        )[] = Object.values(globalTypeMapping)
            .filter((field) => field.updated)
            .flatMap((field) => {
                if (isDataColumnV2(field)) {
                    const dataTableId = "dataTable" in field ? String(field.dataTable) : String(field.dataTableId);
                    return updateDataColumn({
                        input: {
                            dataTableId,
                            id: String(field.id),
                            name: field.name,
                            globalTypeKey: field.globalTypeKey,
                            dataType: field.dataType,
                        },
                    });
                }
                if (isDataTableV2(field)) {
                    return updateDataTable({
                        input: {
                            id: String(field.id),
                            update: {
                                globalTypeKey: String(field.globalTypeKey),
                            },
                            mask: ["globalTypeKey"],
                        },
                    });
                }
                return undefined;
            });

        Promise.all(promises)
            .then(() => handleGlobalColumnTypesSet())
            .catch(() => {
                setLoading(false);
                informUser({ message: fm(messages.failedToUpdateGlobalTypes), type: "error" });
            });
    };

    const fieldGroups = Object.values(tables)
        .filter(
            (config) => !config.dataTable.globalType || config.dataColumns.some((dataColumn) => !dataColumn.globalType)
        )
        .map(({ title, dataTable, dataColumns }) => ({
            groupTitle: title,
            fields: [dataTable, ...dataColumns],
        }));

    return (
        <Stack>
            {globalTypeMapping &&
                (fieldGroups.length > 0 ? (
                    <>
                        <Stack direction="row" pb={2}>
                            <Typography variant="body1">{fm(messages.assignGlobalTypes)}</Typography>
                        </Stack>
                        <Stack direction="row" justifyItems="center" alignItems="center" width="100%">
                            <ConfigureGlobalTypeTable
                                fieldGroups={fieldGroups}
                                globalTypeMapping={globalTypeMapping}
                                setGlobalTypeMapping={setGlobalTypeMapping}
                                onNext={handleSubmit}
                                onBack={onBack}
                                loading={loading}
                            />
                        </Stack>
                    </>
                ) : (
                    <Stack width="100%" pt={2} pl={2}>
                        <Typography variant="h6">{fm(messages.noFieldsToConfigure)}</Typography>
                        <Stack direction="row" gap={1} pt={2} justifyContent="flex-end">
                            <Stack>
                                <Button color="secondary" disabled={loading} onClick={onBack}>
                                    {fm(messages.goBackButton)}
                                </Button>
                            </Stack>
                            <Stack>
                                <Button color="primary" onClick={handleGlobalColumnTypesSet}>
                                    {fm(messages.nextButton)}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                ))}
            {!globalTypeMapping && <CircularProgress />}
        </Stack>
    );
};
