import { Button, Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";

import { fm } from "@/providers/IntlContextProvider";
import { DashboardLibraryItemData } from "@/types/libraryItem";

import { widgetSchema } from "../helpers";
import messages from "../messages";

interface Props {
    newLibraryItem: DashboardLibraryItemData;
    setNewLibraryItem: React.Dispatch<React.SetStateAction<DashboardLibraryItemData>>;
    onNext: () => void;
    onBack: () => void;
}

export const WidgetStep: React.FC<Props> = ({ newLibraryItem, setNewLibraryItem, onNext, onBack }) => {
    const validationSchema = React.useMemo(() => widgetSchema(), []);

    return (
        <Formik
            onSubmit={(formData) => {
                setNewLibraryItem((prevState) => ({ ...prevState, ...formData }));
                onNext();
            }}
            validationSchema={validationSchema}
            initialValues={{ widgets: newLibraryItem.widgets }}
            enableReinitialize
        >
            {(formikProps) => (
                <Form
                    style={{
                        width: "100%",
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography variant="h6">{fm(messages.widgetName)}</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h6">{fm(messages.description)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {newLibraryItem.widgets.map((widget, index) => (
                            <React.Fragment key={`widget-description-${widget.id}`}>
                                <Grid item xs={4} py={1} alignSelf="center" pr={1}>
                                    <Typography>{widget.title}</Typography>
                                </Grid>
                                <Grid item xs={8} py={1} alignSelf="center">
                                    <TextField
                                        name={`widgets[${index}].description`}
                                        value={formikProps.values.widgets[index].description}
                                        label={fm(messages.widgetDescriptionPlaceholder)}
                                        variant="outlined"
                                        onChange={formikProps.handleChange}
                                        fullWidth
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                    <Stack>
                        <Stack direction="row" gap={1} pt={2} justifyContent="flex-end">
                            <Stack>
                                <Button color="secondary" onClick={onBack}>
                                    {fm(messages.goBackButton)}
                                </Button>
                            </Stack>
                            <Stack>
                                <Button color="primary" type="submit">
                                    {fm(messages.nextButton)}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};
