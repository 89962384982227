import { defineMessages } from "react-intl";

const messages = defineMessages({
    fillOutDashboardDetails: {
        id: "libraryModule.publishDashboardPage.fillOutDashboardDetails",
        defaultMessage: "Fill out dashboard details",
    },
    configureDescriptions: {
        id: "libraryModule.publishDashboardPage.configureDescriptions",
        defaultMessage: "Configure descriptions",
    },
    configureGlobalTypes: {
        id: "libraryModule.publishDashboardPage.configureGlobalTypes",
        defaultMessage: "Configure global types",
    },
    reviewAndPublish: {
        id: "libraryModule.publishDashboardPage.reviewAndPublish",
        defaultMessage: "Review and publish",
    },
    publishBreadcrumb: {
        id: "libraryModule.publishDashboardPage.publishBreadcrumb",
        defaultMessage: "Publish",
    },
});

export default messages;
